<template>
    <Header chose_index="2"></Header>
    <article class="container">

        <div style="margin-top: 10px;font-family: 'Microsoft YaHei';">
            我的成绩: {{ base_info.score }}分 {{ base_info.precedence }}位次 {{ base_info.subjects }}
            <span style="margin-left: 30px;">昵称: {{ base_info.name }}</span>
            <span style="margin-left: 15px;">手机号: {{ base_info.phone }}</span>

            <span style="margin-left: 15px;">
				高考年份: <b style="color:#C23B1E ;"> {{ base_info.classes }}</b>
			</span>
            <span style="margin-left: 30px;">
				会员等级: <b style="color:#C23B1E ;"> {{ level_name }}</b>
			</span>
            <span v-if="show_vip == 1" style="display:none;">
				<el-button size="mini" v-if="vip_level < 61" @click="goods_page" style="margin-left: 10px;"
                           type="warning">开通VIP</el-button>
				<el-button size="mini" v-else-if="vip_level < 62" @click="goods_page" style="margin-left: 10px;"
                           type="warning">升级VIP</el-button>
			</span>
            <span v-if="modify_score_times > 0">
                <el-button size="mini" @click="edit_info_click"
                           style="margin-left: 10px;background-color: #C23B1E;color: #FFFFFF;border: 0;">
                    修改信息({{ modify_score_times }}次)
                </el-button>
            </span>
            <el-button v-if="vip_level < 61" size="mini" @click="open_vip"
                       style="margin-left: 10px;background-color: #C23B1E;color: #FFFFFF;border: 0;">开通VIP
            </el-button>
            <div style="text-align: center;margin:0 auto">
                <div class="card_btn">
                    <span
                        v-if="base_info.vip_level < 60 && base_info.open_experience_vip > 0 && base_info.vip_experience_times > 0">
                        <span size="mini" @click="open_experience_vip(1)"
                              style="margin-left: 10px;color: #666666;border: 0;">
                            开通体验VIP({{ base_info.vip_experience_times }}次)
                        </span>
                    </span>
                    <span v-else-if="base_info.vip_level >= 60" style="font-size: 14px;">
                        VIP到期时间: {{ base_info.expires_in }}
                    </span>
                </div>
            </div>
        </div>
        <el-divider></el-divider>
        <div style="margin-top: 30px;">
            <div style="width: 100%;height: 40px;">
                <span style="font-size: 22px;font-weight:bold;">单科成绩</span>
                <span v-if="vip_level>=60 && detailed_info_times > 0">
                    <el-button size="mini" @click="show_more"
                               style="margin-left: 10px;background-color: #C23B1E;color: #FFFFFF;border: 0;">
                        修改成绩({{ detailed_info_times }}次)
                    </el-button>
                </span>
            </div>
            <div style="clear: both;"></div>
        </div>
        <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="8">语文: {{ base_info.chinese_score }}</el-col>
            <el-col :span="8">数学: {{ base_info.math_score }}</el-col>
            <el-col :span="8">英语: {{ base_info.english_score }}</el-col>
        </el-row>
        <el-row :gutter="20" style="margin-bottom: 10px">
            <el-col :span="8">{{ base_info.subjects_name[0] }}: {{ base_info.subject_three_score }}</el-col>
            <el-col :span="8">{{ base_info.subjects_name[1] }}: {{ base_info.subject_two_score }}</el-col>
            <el-col :span="8">{{ base_info.subjects_name[2] }}: {{ base_info.subject_one_score }}</el-col>
        </el-row>
        <el-divider></el-divider>

        <div v-if="approval_info.instructor_id > 0" id="schoolls">
            <div style="font-size: 22px;font-weight: bold;margin-bottom: 20px;">指导老师</div>
            <div>
                <el-row :gutter="20">
                    <el-col :span="12" style="margin-bottom: 20px;">
                        <el-card shadow="hover">
                            <template #header>
                                <div
                                    style="height: 100px;line-height: 100px;display: flex;justify-content: flex-start;align-items: center">
			                        <span style="display: flex;justify-content: space-between;align-items: center">
                                        <img :src="approval_info.header"
                                             style="height: 90px;width:90px;border-radius:50%"/>
                                        <span>{{ approval_info.instructor_name }}</span>
                                    </span>
                                    <span style="padding-left: 20px;">职务:{{ approval_info.title }}</span>
                                </div>
                            </template>
                            <div>
                                <div style="margin-bottom: 10px;">
                                    <span>个人简介: {{ approval_info.introduction }}</span>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
            </div>

            <el-divider></el-divider>
        </div>

        <div>
            <el-tabs type="border-card" v-model="collection_type">
                <el-tab-pane label="提前批" name="collection_pre">
                    <div>
                        批次:
                        <el-radio-group v-model="pre_form.batch" size="small" @change="batch_change">
<!--                            <el-radio-button label="A">提前批A</el-radio-button>-->
                            <el-radio-button label="B">提前批B</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="zhiyuanList">
                        <article>
                            <div id="top_con">
                                <div style="height:5px;"></div>
                                <span style="font-size: 22px;font-weight:bold;">志愿列表</span>
                                <div
                                    style="height: 60px;line-height: 60px;display: flex;justify-content:space-between;align-items:center;">
                                    <span>
                                        <el-button-group>
                                            <el-button @click="collection_p_order_click"
                                                       :class="[pre_form.order_type === 1?'btn1s btn_default':'btn1s']">
                                                收藏顺序
                                            </el-button>
                                            <el-button @click="score_p_order_click"
                                                       v-if="pre_form.batch === 'B' && submit_report === 1"
                                                       :class="[pre_form.order_type === 2?'btn1s btn_default':'btn1s']">
                                                智能排序
                                            </el-button>
                                        </el-button-group>
                                    </span>
                                    <span v-if="form.order_type === 2 && submit_report === 1" @click="show_recommend"
                                          style="cursor:pointer">
                                        点击查看《智能排序》说明
                                    </span>
                                    <span>
                                        <el-checkbox v-model="collect_sort_type"
                                                     label="不再使用系统智能排序"
                                                     v-if="pre_form.batch === 'B' && submit_report === 1"
                                                     style="margin-left: 10px;"
                                                     @click="collect_sort_change"
                                                     size="small"/>
                                        <el-button size="mini" type="warning" v-if="pre_form.batch === 'B'"
                                                   @click="collection_demo">下载导入收藏志愿样表</el-button>
                                        <el-button size="mini" type="warning" v-if="pre_form.batch === 'B'"
                                                   @click="upload_collection(1)">导入收藏志愿</el-button>
                                        <span style="margin-left: 10px;">
                                            <el-button size="mini" type="primary"
                                                       @click="download_p_collection">下载收藏列表</el-button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div id="sortable_list">
                                <div style="width: 100%;text-align: center;" @click="chose_school('pre')"
                                     v-if="pre_tableData.length < 1">
                                    <el-button type="primary" style="width: 200px;background-color: #C23B1E;color: #FFFFFF;border:#C23B1E;">{{ pre_tableData_str }}</el-button>
                                </div>
                                <div v-else>
                                    <el-card class="box-card" v-for="(item, index) in pre_tableData"
                                             style="margin: 0 auto;width: 1200px;">
                                        <div class="no_collection">
                                            <div>
                                                <div
                                                    v-if="item.match_status===1"
                                                    style="position: absolute;"
                                                    class="major_sprint">
                                                </div>
                                                <div
                                                    v-else-if="item.match_status===2"
                                                    style="position: absolute;"
                                                    class="major_stable">
                                                </div>
                                                <div
                                                    v-else
                                                    style="position: absolute;"
                                                    class="major_floors">
                                                </div>
                                                <div
                                                    style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color: white">
                                                    {{ item.match_str }}
                                                </div>
                                            </div>
                                            <div
                                                style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                                            <span
                                                style="font-size: 20px;margin-right: 10px;color: #333333;width:650px;overflow: hidden">
                                                {{ item.majors }}
                                            </span>
                                                <span style="font-size: 16px;color: #e8320a;"
                                                      v-if="item.new_major === 1">
                                                新
                                            </span>
                                            </div>
                                            <div
                                                style="float: right;height:40px;line-height: 40px;text-align: center;padding-right: 10px;margin-top: 10px;">
                                                <el-select
                                                    v-if="pre_form.order_type == 2"
                                                    v-model="item.user_score_rank"
                                                    style="width:100px;"
                                                    @change="score_p_order_change(item.plan_id, item.user_score_rank)">
                                                    <el-option v-for="_item in pre_favorite_list" :key="_item.value"
                                                               :label="_item.label" :value="_item.value">
                                                    </el-option>
                                                </el-select>
                                                &nbsp;&nbsp;
                                                <span v-if="item.collection === 1"
                                                      style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="p_un_favorite(index, item, 1)">取消选择</span>
                                                <span v-else style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="p_favorite(index, item, 1)">选择</span>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="margin-top:15px;margin-bottom:10px;float: left;width: 20%;">
                                                <div style="text-align: center">
                                                    <img @click="school_info(item.school_id, item.school_name, 2)"
                                                         style="height: 140px;cursor: pointer;" :src="item.school_logo">
                                                </div>
                                                <div style="text-align: center">
                                                <span style="cursor:pointer;font-weight: bold;"
                                                      @click="school_info(item.school_id, item.school_name, 2)">
                                                    {{ item.school_name }}
                                                </span>
                                                </div>
                                                <div style="text-align: center;font-size: 12px;margin-top: 5px;">
                                                    [{{ item.nature_str }}]
                                                    <span v-if="item.school_guarantee_rate !== ''">[{{
                                                            item.school_guarantee_rate
                                                        }}]</span>
                                                    <span v-else-if="item.postgraduate_str !== ''">[{{
                                                            item.postgraduate_str
                                                        }}]</span>
                                                </div>
                                                <div v-if="item.school_pre.length > 0"
                                                     style="text-align: center;font-size: 12px;margin: 0 auto;padding: 5px;">
                                                    历史沿革: {{ item.school_pre }}
                                                </div>
                                                <div
                                                    style="text-align: center;font-size: 12px;cursor:pointer;"
                                                    @click="school_map(item.school_name)">
                                                    <span class="iconfont_small">&#xe64b;</span>{{ item.address }}
                                                </div>
                                            </div>
                                            <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                                                <div style="text-align: left;">
                                                    <div
                                                        style="border: #898989 1px dashed;border-radius: 5px;margin-right: 20px;margin-bottom:10px;padding: 5px;"
                                                        v-if="item.foreign_rules.length > 0 || item.foreign_study_mode.length > 0 || item.foreign_universities.length > 0">
                                                        <div style="margin-bottom: 20px;">
                                                            {{ item.type_str }}
                                                        </div>
                                                        <div>
                                                            <div v-if="item.foreign_universities.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;">合作院校:
                                                                {{ item.foreign_universities }}
                                                            </div>
                                                            <div v-if="item.foreign_study_mode.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;color: #e8320a">
                                                                就读模式:
                                                                {{ item.foreign_study_mode }}
                                                            </div>
                                                            <div v-if="item.foreign_rules.length > 0"
                                                                 style="font-size:14px;margin-bottom: 10px;"><a
                                                                style="text-decoration:none;" target="_blank"
                                                                :href="item.foreign_rules">查看招生简章</a></div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="item.postgraduate === 1"
                                                             style="margin-bottom: 20px;">
                                                            {{ item.type_str }}
                                                        </div>
                                                        <div v-else style="margin-bottom: 20px;">{{
                                                                item.type_str
                                                            }}
                                                        </div>
                                                    </div>
                                                    <el-popover placement="top-start" :width="200" trigger="hover"
                                                                :content="system_tips_list[1]['name']">
                                                        <template #reference>
                                                            <el-tag size="mini" style="margin-right: 5px;">
                                                                {{
                                                                    system_tips_list[1]['name']
                                                                }}:<span style="font-weight: bold">{{item.forecast_low_score}}</span>
                                                            </el-tag>
                                                        </template>
                                                    </el-popover>
                                                </div>
                                                <div class="school_tag">
                                                    <el-tag size="mini" type="success"
                                                            v-for="(label, index) in item.label_list"
                                                            :key="index">
                                                        {{ label }}
                                                    </el-tag>
                                                </div>
                                                <span style="float: left;height:60px;line-height: 60px;">
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.discipline_ranking_major_str !== ''">
                                                    {{ item.discipline_ranking_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.postgraduate_major_str !== ''">
                                                    {{ item.postgraduate_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.doctor_major_str !== ''">
                                                    {{ item.doctor_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger"
                                                        v-if="item.first_class_major_str !== ''">
                                                    {{ item.first_class_major_str }}
                                                </el-tag>
                                            </span>
                                            </div>
                                            <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                                                <div>
                                                    <div>招生计划</div>
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <div class="major_info_desc">
                                                                <span>人数</span>&nbsp;|&nbsp;<span>{{
                                                                    item.num
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学年</span>&nbsp;|&nbsp;<span>{{
                                                                    item.length
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学费</span>&nbsp;|&nbsp;<span>{{
                                                                    item.fees
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="8" :offset="1">
                                                            <div class="major_info_desc">
													<span style="float: left;padding-left: 20px;">
														<span>选科</span>&nbsp;|&nbsp;<span>{{
                                                            item.major_group_rule
                                                        }}</span>
													</span>
                                                                <span v-if="item.major_limit === 1"
                                                                      style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                                                <span v-else
                                                                      style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                                            </div>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                                <div style="margin-top: 10px;">
                                                    <div>统计 ( 录取人数 / 线差 / 最低分 / 最低位次 )</div>
                                                    <div class="major_info_desc"
                                                         style="height: 30px;line-height: 30px;padding-left: 20px;">
                                                        <div
                                                            style="width: 33%;display: inline-block;text-align: left;height: 30px;line-height: 30px;"
                                                            v-for="(info, year) in item.historical_data">
                                                            <div
                                                                style="height: 30px;line-height: 30px;display: inline-block;">
                                                                ({{ year }})
                                                                {{ info.number_basics }}&nbsp;/&nbsp;
                                                                {{ info.line_diff_basics }}&nbsp;/&nbsp;
                                                                {{ info.low_score_basics }}&nbsp;/&nbsp;
                                                                {{ info.precedence_basics }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 20px;" v-if="item.remarks.length > 0">
                                                    <div>备注</div>
                                                    <div class="major_remarks" style="padding-left: 20px;">
                                                        <div v-html="item.remarks"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div style="margin-top: 20px;"></div>
                            <el-pagination @size-change="handlepSizeChange" @current-change="handlepCurrentChange"
                                           v-model:currentPage="pre_form.page" :page-sizes="[10, 20, 50, 100]"
                                           :page-size="pre_form.page_size"
                                           layout="total, sizes, prev, pager, next, jumper"
                                           :total="pre_form.total">
                            </el-pagination>
                        </article>

                    </div>
                </el-tab-pane>
                <el-tab-pane label="本科" name="collection_undergraduate">
                    <div v-if="report_list.length > 0">
                        <div>
                            <div style="font-size: 22px;font-weight:bold;margin-bottom: 20px;">我的报告</div>
                            <el-form label-width="120px" :inline="true">
                                <el-form-item label-width="50px" v-for="(item, index) in report_list">
                                    <i class="el-icon-s-order" style="color: #c30000;font-size: 20px;"></i>
                                    {{ item.create_str }}
                                    <span style="margin-left: 15px;" size="small">{{ item.status_str }}</span>
                                    <span v-if="item.status == 2">
                                    <el-button v-if="item.pdf_url.length > 0"
                                               style="margin-left: 20px;background-color: #C23B1E;color: #FFFFFF;border: 0;"
                                               size="mini" @click="look_report(item.pdf_url)">
                                        查看
                                    </el-button>
                                    <el-button v-else
                                               style="margin-left: 20px;background-color: #C23B1E;color: #FFFFFF;border: 0;"
                                               size="mini" @click="open_report(item.id)">
                                        查看
                                    </el-button>
                                    <el-button type="info"
                                               size="mini" @click="remove_report(item.id)">
                                        删除
                                    </el-button>
                                    </span>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-divider></el-divider>
                    </div>
                    <div class="zhiyuanList">
                        <article>
                            <div id="top_con">
                                <div style="height:5px;"></div>
                                <span style="font-size: 22px;font-weight:bold;">志愿列表</span>
                                <div
                                    style="height: 60px;line-height: 60px;display: flex;justify-content:space-between;align-items:center;">
                                    <span>
                                        <el-button-group>
                                            <el-button @click="collection_order_click"
                                                       :class="[form.order_type === 1?'btn1s btn_default':'btn1s']">
                                                收藏顺序
                                            </el-button>
                                            <el-button @click="score_order_click(form.score_rank)"
                                                       v-if="submit_report === 1"
                                                       :class="[form.order_type === 2?'btn1s btn_default':'btn1s']">
                                                智能排序
                                            </el-button>
                                        </el-button-group>
                                    </span>
                                    <span v-if="form.order_type === 2 && submit_report === 1" @click="show_recommend"
                                          style="cursor:pointer">
                                        点击查看《智能排序》说明
                                    </span>

                                    <div style="display: inline-block">
                                        <span style="margin-left: 10px;">
                                            <el-checkbox v-model="collect_sort_type"
                                                         label="不再使用系统智能排序"
                                                         style="margin-right: 10px;"
                                                         v-if="submit_report === 1"
                                                         @click="collect_sort_change"
                                                         size="small"/>
                                            <el-button size="mini" type="warning"
                                                       @click="collection_demo">下载导入收藏志愿样表</el-button>
                                            <el-button size="mini" type="warning"
                                                       @click="upload_collection(2)">导入收藏志愿</el-button>
                                            <el-button size="mini" type="primary"
                                                       @click="download_collection">下载收藏列表</el-button>
                                        </span>
                                        <span v-if="approval_info.status == 1" style="margin-left: 10px;">
                                            <el-button v-if="submit_times > 0" size="mini" type="primary">审核中</el-button>
                                        </span>
                                        <span v-else style="margin-left: 10px;">
                                            <el-button v-if="submit_times >= 0 && vip_level > 0 && submit_report === 1"
                                                                       size="mini"
                                                                       style="background-color: #C23B1E;color: #FFFFFF;border: 0;"
                                                                       @click="approval_report">
                                            提交审核(剩余次数:{{ submit_times }})</el-button>
                                        </span>
                                    </div>
                                </div>
                                <div v-if="form.order_type > 1"
                                    style="display: flex;justify-content:space-between;align-items:center;margin-bottom: 10px;">
                                    <span>
                                        <el-radio-group v-model="form.score_rank">
                                            <el-radio @change="score_rank_change" :label="0" size="large">推荐排序</el-radio>
                                            <el-radio @change="score_rank_change" :label="1" size="large">分数排序</el-radio>
                                        </el-radio-group>
<!--                                        <el-button-group>-->
<!--                                            <el-button @click="score_order_click(0)"-->
<!--                                                       :class="[form.score_rank === 0?'btn1s btn_default':'btn1s']">-->
<!--                                                推荐排序-->
<!--                                            </el-button>-->
<!--                                            <el-button @click="score_order_click(1)"-->
<!--                                                       :class="[form.score_rank === 1?'btn1s btn_default':'btn1s']">-->
<!--                                                分数排序-->
<!--                                            </el-button>-->
<!--                                        </el-button-group>-->
                                    </span>
                                </div>
                            </div>
                            <div id="sortable_list">
                                <div style="width: 100%;text-align: center;" @click="chose_school('ben')"
                                     v-if="tableData.length < 1">
                                    <el-button type="primary" style="width: 200px;background-color: #C23B1E;color: #FFFFFF;border:#C23B1E;">{{ tableData_str }}</el-button>
                                </div>
                                <div v-else>
                                    <el-card v-loading="loading" class="box-card" v-for="(item, index) in tableData"
                                             style="margin: 0 auto;width: 1200px;">
                                        <div class="no_collection">
                                            <div>
                                                <div
                                                    v-if="item.match_status===1"
                                                    style="position: absolute;"
                                                    class="major_sprint">
                                                </div>
                                                <div
                                                    v-else-if="item.match_status===2"
                                                    style="position: absolute;"
                                                    class="major_stable">
                                                </div>
                                                <div
                                                    v-else
                                                    style="position: absolute;"
                                                    class="major_floors">
                                                </div>
                                                <div
                                                    style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color: white">
                                                    {{ item.match_str }}
                                                </div>
                                            </div>
                                            <div
                                                style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                                              <span
                                                  style="font-size: 20px;margin-right: 10px;color: #333333;width:650px;overflow: hidden"
                                                  @click="show_majors_job(item.majors_list)"
                                              >
                                                {{ item.majors }}
                                              </span>
                                                <span style="font-size: 16px;color: #e8320a;"
                                                      v-if="item.new_major === 1">
                                                新
                                              </span>
                                            </div>
                                            <div
                                                style="float: right;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                                                <div style="display: none;">
                                                    <el-tag v-if="item.district_info === 0" type="info" effect="plain"
                                                            style="margin-right: 5px;">
                                                        地域
                                                    </el-tag>
                                                    <el-tag v-else-if="item.district_info === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">地域
                                                    </el-tag>
                                                    <el-tag v-else-if="item.district_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">地域
                                                    </el-tag>

                                                    <el-tag v-if="item.school_topic_info === 0" type="info"
                                                            style="margin-right: 5px;"
                                                            effect="plain">学校
                                                    </el-tag>
                                                    <el-tag v-else-if="item.school_topic_info === 1" type="danger"
                                                            effect="dark"
                                                            style="margin-right: 5px;">学校
                                                    </el-tag>
                                                    <el-tag v-else-if="item.school_topic_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">学校
                                                    </el-tag>

                                                    <el-tag v-if="item.major_topic_info === 0" type="info"
                                                            style="margin-right: 5px;"
                                                            effect="plain">专业
                                                    </el-tag>
                                                    <el-tag v-else-if="item.major_topic_info === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">专业
                                                    </el-tag>
                                                    <el-tag v-else-if="item.major_topic_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">专业
                                                    </el-tag>

                                                    <el-tag v-if="item.score_topic === 0" type="info" effect="plain"
                                                            style="margin-right: 5px;">策略
                                                    </el-tag>
                                                    <el-tag v-else-if="item.score_topic === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">策略
                                                    </el-tag>
                                                    <el-tag v-else-if="item.score_topic === 2" type="success"
                                                            effect="dark"
                                                            style="margin-right: 5px;">
                                                        策略
                                                    </el-tag>
                                                </div>
                                                <div
                                                    style="float: right;height:40px;line-height: 40px;text-align: center;padding-right: 10px;margin-top: 10px;">
                                                <el-select
                                                    v-if="form.order_type == 2"
                                                    v-model="item.user_score_rank"
                                                    style="width:100px;"
                                                    @change="score_order_change(item.plan_id, item.user_score_rank)">
                                                    <el-option v-for="_item in favorite_list" :key="_item.value"
                                                               :label="_item.label" :value="_item.value">
                                                    </el-option>
                                                </el-select>
                                                &nbsp;&nbsp;
                                                <span v-if="item.collection === 1"
                                                      style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="un_favorite(index, item, 1)">取消选择</span>
                                                <span v-else style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="favorite(index, item, 1)">选择</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="margin-top:15px;margin-bottom:10px;float: left;width: 20%;">
                                                <div style="text-align: center">
                                                    <img @click="school_info(item.school_id, item.school_name, 1)"
                                                         style="height: 140px;cursor: pointer;" :src="item.school_logo">
                                                </div>
                                                <div style="text-align: center">
                                                <span style="cursor:pointer;font-weight: bold;"
                                                      @click="school_info(item.school_id, item.school_name, 1)">
                                                    {{ item.school_name }}
                                                </span>
                                                </div>
                                                <div style="text-align: center;font-size: 12px;margin-top: 5px;">
                                                    [{{ item.nature_str }}]
                                                    <span v-if="item.school_guarantee_rate !== ''">[{{
                                                            item.school_guarantee_rate
                                                        }}]</span>
                                                    <span v-else-if="item.postgraduate_str !== ''">[{{
                                                            item.postgraduate_str
                                                        }}]</span>
                                                </div>
                                                <div v-if="item.school_pre.length > 0"
                                                     style="text-align: center;font-size: 12px;margin: 0 auto;padding: 5px;">
                                                    历史沿革: {{ item.school_pre }}
                                                </div>
                                                <div
                                                    style="text-align: center;font-size: 12px;cursor:pointer;"
                                                    @click="school_map(item.school_name)">
                                                    <span class="iconfont_small">&#xe64b;</span>{{ item.address }}
                                                </div>
                                            </div>
                                            <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                                                <div style="text-align: left;">
                                                    <div
                                                        style="border: #898989 1px dashed;border-radius: 5px;margin-right: 20px;margin-bottom:10px;padding: 5px;"
                                                        v-if="item.foreign_rules.length > 0 || item.foreign_study_mode.length > 0 || item.foreign_universities.length > 0">
                                                        <div style="margin-bottom: 20px;">
                                                            {{ item.type_str }}

                                                        </div>
                                                        <div>
                                                            <div v-if="item.foreign_universities.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;">合作院校:
                                                                {{ item.foreign_universities }}
                                                            </div>
                                                            <div v-if="item.foreign_study_mode.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;color: #e8320a">
                                                                就读模式:
                                                                {{ item.foreign_study_mode }}
                                                            </div>
                                                            <div v-if="item.foreign_rules.length > 0"
                                                                 style="font-size:14px;margin-bottom: 10px;"><a
                                                                style="text-decoration:none;" target="_blank"
                                                                :href="item.foreign_rules">查看招生简章</a></div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="item.postgraduate === 1"
                                                             style="margin-bottom: 20px;">
                                                            {{ item.type_str }}

                                                        </div>
                                                        <div v-else style="margin-bottom: 20px;">{{
                                                                item.type_str
                                                            }}
                                                        </div>
                                                    </div>

                                                    <el-popover placement="top-start" :width="200" trigger="hover"
                                                                :content="system_tips_list[2]['name']">
                                                        <template #reference>
                                                            <el-tag size="mini" style="margin-right: 5px;">
                                                                {{ system_tips_list[2]['name'] }}:{{ item.use_sum }}
                                                            </el-tag>
                                                        </template>
                                                    </el-popover>
                                                    <el-popover placement="top-start" :width="200" trigger="hover"
                                                                :content="system_tips_list[1]['name']">
                                                        <template #reference>
                                                            <el-tag size="mini" style="margin-right: 5px;">
                                                                {{
                                                                    system_tips_list[1]['name']
                                                                }}:<span style="font-weight: bold">{{item.forecast_low_score}}</span>
                                                            </el-tag>
                                                        </template>
                                                    </el-popover>
                                                </div>
                                                <div class="school_tag">
                                                    <el-tag size="mini" type="success"
                                                            v-for="(label, index) in item.label_list"
                                                            :key="index">
                                                        {{ label }}
                                                    </el-tag>
                                                </div>
                                                <span style="float: left;height:60px;line-height: 60px;"
                                                      v-if="show_major_label === 1">
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.discipline_ranking_major_str !== ''">
                                                    {{ item.discipline_ranking_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.postgraduate_major_str !== ''">
                                                    {{ item.postgraduate_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.doctor_major_str !== ''">
                                                    {{ item.doctor_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger"
                                                        v-if="item.first_class_major_str !== ''">
                                                    {{ item.first_class_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.nation_feature !== undefined && item.nation_feature !== ''">
                                                    {{ item.nation_feature }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.double_first_class_level !== undefined && item.double_first_class_level !== ''">
                                                    {{ item.double_first_class_level }}
                                                </el-tag>
                                            </span>
                                            </div>
                                            <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                                                <div>
                                                    <div>招生计划</div>
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <div class="major_info_desc">
                                                                <span>人数</span>&nbsp;|&nbsp;<span>{{
                                                                    item.num
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学年</span>&nbsp;|&nbsp;<span>{{
                                                                    item.length
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学费</span>&nbsp;|&nbsp;<span>{{
                                                                    item.fees
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="8" :offset="1">
                                                            <div class="major_info_desc">
													<span style="float: left;padding-left: 20px;">
														<span>选科</span>&nbsp;|&nbsp;<span>{{
                                                            item.major_group_rule
                                                        }}</span>
													</span>
                                                                <span v-if="item.major_limit === 1"
                                                                      style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                                                <span v-else
                                                                      style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                                            </div>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                                <div style="margin-top: 10px;">
                                                    <div>统计 ( 录取人数 / 线差 / 最低分 / 最低位次 )</div>
                                                    <div class="major_info_desc"
                                                         style="height: 30px;line-height: 30px;padding-left: 20px;">
                                                        <div
                                                            style="width: 33%;display: inline-block;text-align: left;height: 30px;line-height: 30px;"
                                                            v-for="(info, year) in item.historical_data">
                                                            <div
                                                                style="height: 30px;line-height: 30px;display: inline-block;">
                                                                ({{ year }})
                                                                {{ info.number_basics }}&nbsp;/&nbsp;
                                                                {{ info.line_diff_basics }}&nbsp;/&nbsp;
                                                                {{ info.low_score_basics }}&nbsp;/&nbsp;
                                                                {{ info.precedence_basics }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 10px;" v-if="item.remarks.length > 0">
                                                    <div>备注</div>
                                                    <div class="major_remarks" style="padding-left: 20px;">
                                                        <div v-html="item.remarks"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div style="margin-top: 20px;"></div>
                            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                           v-model:currentPage="form.page" :page-sizes="[10, 20, 50, 100]"
                                           :page-size="form.page_size"
                                           layout="total, sizes, prev, pager, next, jumper" :total="form.total">
                            </el-pagination>
                        </article>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="专科" name="collection_speciality">
                    <div v-if="vocation_report_list.length > 0">
                        <div>
                            <div style="font-size: 22px;font-weight:bold;margin-bottom: 20px;">我的报告</div>
                            <el-form label-width="120px" :inline="true">
                                <el-form-item label-width="50px" v-for="(item, index) in vocation_report_list">
                                    <i class="el-icon-s-order" style="color: #c30000;font-size: 20px;"></i>
                                    {{ item.create_str }}
                                    <span style="margin-left: 15px;" size="small">{{ item.status_str }}</span>
                                    <el-button
                                        style="margin-left: 20px;background-color: #C23B1E;color: #FFFFFF;border: 0;"
                                        size="mini" @click="open_report(item.id)">
                                        查看
                                    </el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-divider></el-divider>
                    </div>
                    <div class="zhiyuanList">
                        <article>
                            <div id="top_con">
                                <div style="height:5px;"></div>
                                <span style="font-size: 22px;font-weight:bold;">志愿列表</span>
                                <div
                                    style="height: 60px;line-height: 60px;display: flex;justify-content:space-between;align-items:center;">
							        <span>
                                        <el-button-group>
                                            <el-button @click="collection_v_order_click"
                                                       :class="[vocation_form.order_type === 1?'btn1s btn_default':'btn1s']">
                                                收藏顺序
                                            </el-button>
                                            <el-button @click="score_v_order_click"
                                                       v-if="submit_report === 1"
                                                       :class="[vocation_form.order_type === 2?'btn1s btn_default':'btn1s']">
                                                智能排序
                                            </el-button>
                                        </el-button-group>
                                    </span>
                                    <span v-if="form.order_type === 2 && submit_report === 1" @click="show_recommend"
                                          style="cursor:pointer">
                                        点击查看《智能排序》说明
                                    </span>
                                    <span>
                                        <el-checkbox v-model="collect_sort_type"
                                                     label="不再使用系统智能排序"
                                                     v-if="submit_report === 1"
                                                     style="margin-left: 10px;"
                                                     @click="collect_sort_change"
                                                     size="small"/>
                                        <el-button size="mini" type="warning"
                                                   @click="collection_demo">下载导入收藏志愿样表</el-button>
                                        <el-button size="mini" type="warning"
                                                   @click="upload_collection(3)">导入收藏志愿</el-button>
                                        <span style="margin-left: 10px;">
                                            <el-button size="mini" type="primary"
                                                       @click="download_v_collection">下载收藏列表</el-button>
                                        </span>
                                    </span>
                                </div>
                            </div>
                            <div id="sortable_list">
                                <div style="width: 100%;text-align: center;" @click="chose_school('vocation')"
                                     v-if="vocation_tableData.length < 1">
                                    <el-button type="primary" style="width: 200px;background-color: #C23B1E;color: #FFFFFF;border:#C23B1E;">{{ vocation_tableData_str }}</el-button>
                                </div>
                                <div v-else>
                                    <el-card v-loading="loading" class="box-card"
                                             v-for="(item, index) in vocation_tableData"
                                             style="margin: 0 auto;width: 1200px;">
                                        <div class="no_collection">
                                            <div>
                                                <div
                                                    v-if="item.match_status===1"
                                                    style="position: absolute;"
                                                    class="major_sprint">
                                                </div>
                                                <div
                                                    v-else-if="item.match_status===2"
                                                    style="position: absolute;"
                                                    class="major_stable">
                                                </div>
                                                <div
                                                    v-else
                                                    style="position: absolute;"
                                                    class="major_floors">
                                                </div>
                                                <div
                                                    style="position: absolute;height:60px;width:40px;line-height:60px;padding-right:10px;text-align: right;border-radius: 5px;color: white">
                                                    {{ item.match_str }}
                                                </div>
                                            </div>
                                            <div
                                                style="float: left;margin-left:65px;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                                            <span
                                                style="font-size: 20px;margin-right: 10px;color: #333333;width:650px;overflow: hidden">
                                                {{ item.majors }}
                                            </span>
                                                <span style="font-size: 16px;color: #e8320a;"
                                                      v-if="item.new_major === 1">
                                                新
                                            </span>
                                            </div>
                                            <div
                                                style="float: right;height:60px;line-height: 60px;text-align: center;padding-right: 10px;">
                                                <div style="display: none;">
                                                    <el-tag v-if="item.district_info === 0" type="info" effect="plain"
                                                            style="margin-right: 5px;">
                                                        地域
                                                    </el-tag>
                                                    <el-tag v-else-if="item.district_info === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">地域
                                                    </el-tag>
                                                    <el-tag v-else-if="item.district_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">地域
                                                    </el-tag>

                                                    <el-tag v-if="item.school_topic_info === 0" type="info"
                                                            style="margin-right: 5px;"
                                                            effect="plain">学校
                                                    </el-tag>
                                                    <el-tag v-else-if="item.school_topic_info === 1" type="danger"
                                                            effect="dark"
                                                            style="margin-right: 5px;">学校
                                                    </el-tag>
                                                    <el-tag v-else-if="item.school_topic_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">学校
                                                    </el-tag>

                                                    <el-tag v-if="item.major_topic_info === 0" type="info"
                                                            style="margin-right: 5px;"
                                                            effect="plain">专业
                                                    </el-tag>
                                                    <el-tag v-else-if="item.major_topic_info === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">专业
                                                    </el-tag>
                                                    <el-tag v-else-if="item.major_topic_info === 2" type="success"
                                                            style="margin-right: 5px;"
                                                            effect="dark">专业
                                                    </el-tag>

                                                    <el-tag v-if="item.score_topic === 0" type="info" effect="plain"
                                                            style="margin-right: 5px;">策略
                                                    </el-tag>
                                                    <el-tag v-else-if="item.score_topic === 1" type="danger"
                                                            style="margin-right: 5px;"
                                                            effect="dark">策略
                                                    </el-tag>
                                                    <el-tag v-else-if="item.score_topic === 2" type="success"
                                                            effect="dark"
                                                            style="margin-right: 5px;">
                                                        策略
                                                    </el-tag>
                                                </div>
                                                <div
                                                    style="float: right;height:40px;line-height: 40px;text-align: center;padding-right: 10px;margin-top: 10px;">
                                                <el-select
                                                    v-if="vocation_form.order_type == 2"
                                                    v-model="item.user_score_rank"
                                                    style="width:100px;"
                                                    @change="score_v_order_change(item.plan_id, item.user_score_rank)">
                                                    <el-option v-for="_item in vocation_favorite_list"
                                                               :key="_item.value"
                                                               :label="_item.label" :value="_item.value">
                                                    </el-option>
                                                </el-select>
                                                &nbsp;&nbsp;
                                                <span v-if="item.collection === 1"
                                                      style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="v_un_favorite(index, item, 1)">取消选择</span>
                                                <span v-else style="float: right;padding: 0 20px;cursor: pointer;border: 1px solid #C23B1E;background-color:#C23B1E;color:white;border-radius: 5px;"
                                                      @click="v_favorite(index, item, 1)">选择</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                                                <div style="text-align: center">
                                                    <img @click="school_info(item.school_id, item.school_name, 3)"
                                                         style="height: 160px;cursor: pointer;" :src="item.school_logo">
                                                </div>
                                                <div style="text-align: center">
                                                <span style="cursor:pointer;font-weight: bold;"
                                                      @click="school_info(item.school_id, item.school_name, 3)">
                                                    {{ item.school_name }}
                                                </span>
                                                    [{{ item.nature_str }}]
                                                    <span v-if="item.school_guarantee_rate !== ''">[{{
                                                            item.school_guarantee_rate
                                                        }}]</span>
                                                    <span v-else-if="item.postgraduate_str !== ''">[{{
                                                            item.postgraduate_str
                                                        }}]</span>
                                                </div>
                                                <div v-if="item.school_pre.length > 0"
                                                     style="text-align: center;font-size: 12px;margin: 0 auto;padding: 10px;">
                                                    历史沿革: {{ item.school_pre }}
                                                </div>
                                                <div
                                                    style="text-align: center;font-size: 12px;margin-top: 10px;cursor:pointer;"
                                                    @click="school_map(item.school_name)">
                                                    <span class="iconfont_small">&#xe64b;</span>{{ item.address }}
                                                </div>
                                            </div>
                                            <div style="margin-top:30px;margin-bottom:30px;float: left;width: 20%;">
                                                <div style="text-align: left;">
                                                    <div
                                                        style="border: #898989 1px dashed;border-radius: 5px;margin-right: 20px;margin-bottom:10px;padding: 5px;"
                                                        v-if="item.foreign_rules.length > 0 || item.foreign_study_mode.length > 0 || item.foreign_universities.length > 0">
                                                        <div style="margin-bottom: 20px;">
                                                            {{ item.type_str }}

                                                        </div>
                                                        <div>
                                                            <div v-if="item.foreign_universities.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;">合作院校:
                                                                {{ item.foreign_universities }}
                                                            </div>
                                                            <div v-if="item.foreign_study_mode.length > 0"
                                                                 style="font-size:12px;margin-bottom: 5px;color: #e8320a">
                                                                就读模式:
                                                                {{ item.foreign_study_mode }}
                                                            </div>
                                                            <div v-if="item.foreign_rules.length > 0"
                                                                 style="font-size:14px;margin-bottom: 10px;"><a
                                                                style="text-decoration:none;" target="_blank"
                                                                :href="item.foreign_rules">查看招生简章</a></div>
                                                        </div>
                                                    </div>
                                                    <div v-else>
                                                        <div v-if="item.postgraduate === 1"
                                                             style="margin-bottom: 20px;">
                                                            {{ item.type_str }}

                                                        </div>
                                                        <div v-else style="margin-bottom: 20px;">{{
                                                                item.type_str
                                                            }}
                                                        </div>
                                                    </div>
                                                    <el-popover placement="top-start" :width="200" trigger="hover"
                                                                :content="system_tips_list[1]['name']">
                                                        <template #reference>
                                                            <el-tag size="mini" style="margin-right: 5px;">
                                                                {{
                                                                    system_tips_list[1]['name']
                                                                }}:<span style="font-weight: bold">{{item.forecast_low_score}}</span>
                                                            </el-tag>
                                                        </template>
                                                    </el-popover>
                                                </div>
                                                <div class="school_tag">
                                                    <el-tag size="mini" type="success"
                                                            v-for="(label, index) in item.label_list"
                                                            :key="index">
                                                        {{ label }}
                                                    </el-tag>
                                                </div>
                                                <span style="float: left;height:60px;line-height: 60px;">
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.discipline_ranking_major_str !== ''">
                                                    {{ item.discipline_ranking_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.postgraduate_major_str !== ''">
                                                    {{ item.postgraduate_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger" style="margin-right: 5px;"
                                                        v-if="item.doctor_major_str !== ''">
                                                    {{ item.doctor_major_str }}
                                                </el-tag>
                                                <el-tag size="mini" type="danger"
                                                        v-if="item.first_class_major_str !== ''">
                                                    {{ item.first_class_major_str }}
                                                </el-tag>
                                            </span>
                                            </div>
                                            <div class="major_list" style="border-left: 1px #EBEBEB solid;">
                                                <div>
                                                    <div>招生计划</div>
                                                    <el-row>
                                                        <el-col :span="4">
                                                            <div class="major_info_desc">
                                                                <span>人数</span>&nbsp;|&nbsp;<span>{{
                                                                    item.num
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学年</span>&nbsp;|&nbsp;<span>{{
                                                                    item.length
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="4" :offset="1">
                                                            <div class="major_info_desc">
                                                                <span>学费</span>&nbsp;|&nbsp;<span>{{
                                                                    item.fees
                                                                }}</span>
                                                            </div>
                                                        </el-col>
                                                        <el-col :span="8" :offset="1">
                                                            <div class="major_info_desc">
													<span style="float: left;padding-left: 20px;">
														<span>选科</span>&nbsp;|&nbsp;<span>{{
                                                            item.major_group_rule
                                                        }}</span>
													</span>
                                                                <span v-if="item.major_limit === 1"
                                                                      style="float: right;padding-right: 20px;color: #24C599">选科匹配</span>
                                                                <span v-else
                                                                      style="float: right;padding-right: 20px;color: #999999">选科不匹配</span>
                                                            </div>
                                                        </el-col>
                                                    </el-row>
                                                </div>
                                                <div style="margin-top: 10px;">
                                                    <div>统计 ( 录取人数 / 最低分 / 最低位次 )</div>
                                                    <div class="major_info_desc"
                                                         style="height: 30px;line-height: 30px;padding-left: 20px;">
                                                        <div
                                                            style="width: 33%;display: inline-block;text-align: left;height: 30px;line-height: 30px;"
                                                            v-for="(info, year) in item.historical_data">
                                                            <div
                                                                style="height: 30px;line-height: 30px;display: inline-block;">
                                                                ({{ year }})
                                                                {{ info.number_basics }}&nbsp;/&nbsp;
                                                                {{ info.line_diff_basics }}&nbsp;/&nbsp;
                                                                {{ info.low_score_basics }}&nbsp;/&nbsp;
                                                                {{ info.precedence_basics }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="margin-top: 20px;" v-if="item.remarks.length > 0">
                                                    <div>备注</div>
                                                    <div class="major_remarks" style="padding-left: 20px;">
                                                        <div v-html="item.remarks"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                            <div style="margin-top: 20px;"></div>
                            <el-pagination @size-change="handlevSizeChange" @current-change="handlevCurrentChange"
                                           v-model:currentPage="vocation_form.page" :page-sizes="[10, 20, 50, 100]"
                                           :page-size="vocation_form.page_size"
                                           layout="total, sizes, prev, pager, next, jumper"
                                           :total="vocation_form.total">
                            </el-pagination>
                        </article>

                    </div>
                </el-tab-pane>
            </el-tabs>

        </div>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999 ;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <AddMoreInfo :show="show_more_info" :subjects_name="subjects_name" @fatherMethod="fatherMethod"></AddMoreInfo>
    <EditPassword :show="show_pass_info"></EditPassword>
    <el-dialog v-model="user_is_register" title="请验证手机号" :center="true" width="380px"
               :close-on-press-escape="false"
               :show-close="false" :before-close="handleClose">
        <div style="text-align: center">
            <div>
                <div>
                    <el-input v-model="phone" placeholder="手机号">
                        <template #prepend>手机号:</template>
                    </el-input>
                </div>
            </div>
            <div style="margin-top: 20px;">
                <el-input v-model="captcha" placeholder="验证码">
                    <template #prepend>验证码:</template>
                </el-input>
            </div>
            <div style="margin-top: 20px;">
                <el-button @click="get_phone_code" v-if="show_get_phone_code" type="primary">获取验证码</el-button>
                <el-button @click="send_phone_code" type="primary">验证</el-button>
            </div>
        </div>
    </el-dialog>
    <EditInfo :show="show_edit_info" @fatherMethod="edit_info_fun"></EditInfo>
    <el-dialog v-model="need_vip" title="该功能需要VIP 是否开通VIP功能" :center="true" :close-on-press-escape="false"
               :show-close="false" width="400px" :before-close="handleClose">
        <div style="text-align: center">
            <div>
                <el-button type="primary" @click="open_vip" plain>开通</el-button>
            </div>
        </div>
    </el-dialog>
    <el-dialog v-model="rank_status" title="提醒" :show-close="false" width="30%" :before-close="handleClose">
        <span>报告中志愿展示顺序</span>
        <template #footer>
			<span class="dialog-footer">
				<el-button @click="approval(1)">收藏顺序</el-button>
				<el-button type="primary" @click="approval(2)">智能排序</el-button>
			</span>
        </template>
    </el-dialog>
    <el-dialog v-model="remind_status" title="提示" :show-close="false" width="30%" :before-close="handleClose">
        <div style="font-size: 16px;margin-left: 15px;margin-bottom:15px;">
            <div>1、推荐排序：推荐排序是考虑了院校层次、院校性质、院校所在地域、学科评估、硕博点建设情况、冷热门、校内优劣势等因素的综合评价排序，系统允许考生根据个性化需求修改推荐排序，请确保修改后的排序是您的真实意愿表达！</div>
            <div>2、分数排序：推荐排序是根据考生选定的志愿，按照等效分由高到低进行排序。系统允许考生根据个性化需求修改分数排序，请确保修改后的排序是您的真实意愿表达！</div>
            <div>3、切换排序方式时，手动调整的排序将失效，请谨慎操作！</div>
        </div>
        <template #footer>
			<span class="dialog-footer">
                <el-checkbox v-model="recommend_ranking" label="不再提示" size="large"></el-checkbox>
				<el-button style="background-color: #C23B1E;color: #FFFFFF;border: 0;margin-left: 10px;"
                           @click="recommend_rank_click">
					我已知晓
                </el-button>
			</span>
        </template>
    </el-dialog>
    <el-dialog v-model="favorites_status" title="专业特别提示" :show-close="false" width="30%"
               :before-close="handleClose">
        <div style="font-size: 16px;margin-left: 35px;margin-bottom:15px;" v-for="item in major_remind_list">{{
                item
            }}
        </div>
        <template #footer>
			<span class="dialog-footer">
				<el-button @click="favorites_status = false">放弃</el-button>
				<el-button type="primary" @click="continue_favorite">继续选择</el-button>
			</span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="show_user_agreement"
        title="用户协议"
        :show-close="false"
        width="50%"
        :before-close="handleClose"
    >
        <div style="height:500px;overflow: auto">
            <div v-html="agreement"></div>
        </div>
        <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="confirm_agreement">确认</el-button>
      </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="show_majors_job_info"
        title="就业方向"
        width="60%"
        :before-close="majors_job_info_close">
        <div style="overflow-y: scroll;max-height: 600px;">
            <div v-if="majors_job_info === null || majors_job_info.length < 1">暂无数据</div>
            <div v-else-if="majors_job_list.length < 1">加载中...</div>
            <div v-else>
                <div v-for="item in majors_job_info">
                    <div style="font-size: 18px;font-weight: bold;color:#f56c6c;">
                        {{ item.name }}
                        <span style="margin-left: 20px;font-size:14px;cursor: pointer;color:#333333;"
                              @click="show_major_detail(item.name, item.code)">点击查看详情>></span>
                    </div>
                    <div style="margin-bottom: 10px;" v-if="majors_job_list[item.code] !== undefined">
                        <span style="margin-right: 10px;color:#666666;"
                              v-html="majors_job_list[item.code]['job_orientation']"></span>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" type="primary" @click="majors_job_info_close">关闭</el-button>
          </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="remove_report_status"
        title="提醒"
        width="30%"
        :before-close="reportHandleClose"
    >
        <span>您确定删除该报告？</span>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="cannel_remove_report">取消</el-button>
        <el-button type="primary" @click="confirm_remove_report">确定</el-button>
      </span>
        </template>
    </el-dialog>
    <el-dialog v-model="show_vip_60_status" title="开通体验VIP" :width="850">
        <el-form>
            <el-form-item label="序列号" :label-width="100">
                <el-input v-model="serial_number[0]" autocomplete="off" size="small"
                          style="width: 100px;text-align: center;"/>
                -
                <el-input v-model="serial_number[1]" autocomplete="off" size="small"
                          style="width: 100px;text-align: center;"/>
                -
                <el-input v-model="serial_number[2]" autocomplete="off" size="small"
                          style="width: 100px;text-align: center;"/>
                -
                <el-input v-model="serial_number[3]" autocomplete="off" size="small"
                          style="width: 100px;text-align: center;"/>
            </el-form-item>
            <el-form-item label="说明" :label-width="100">
                <div>体验vip在体验期间享有vip的全部权限，包括但不限于志愿推荐、志愿排序、志愿审核等。</div>
            </el-form-item>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="show_vip_60_status = false">取消开通</el-button>
                <el-button type="primary"
                           @click="open_experience_vip(2)"
                           :disabled="open_vip_60_btn_status === true">开通体验</el-button>
            </span>
        </template>
    </el-dialog>
    <el-dialog v-model="upload_collection_status" title="导入收藏志愿" :width="1000"
               :before-close="errorCollectionHandleClose">
        <el-table border stripe height="500" :data="upload_collection_list">
            <el-table-column property="school_name" label="学校名称"/>
            <el-table-column property="school_code" label="学校code" width="100"/>
            <el-table-column property="majors" label="专业名称"/>
            <el-table-column property="major_num" label="专业code" width="100"/>
            <el-table-column property="result_str" label="结果" width="100"/>
            <el-table-column property="result_str" label="结果" width="120">
                <template #default="scope">
                    <el-button
                        v-if="scope.row.result != 1 "
                        type="danger"
                        size="small"
                    >
                        {{ scope.row.result_str }}
                    </el-button>
                    <el-button
                        v-else
                        type="success"
                        size="small"
                    >
                        {{ scope.row.result_str }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="upload_collection_status = false">关闭</el-button>
                <el-button type="primary" @click="download_error_collection">导出错误数据</el-button>
            </span>
        </template>
    </el-dialog>

    <input
        type="file"
        id="file"
        name="file"
        @change="upload_file"
        style="display: none"
    />
</template>

<script>
import http from '@/utils/http'
import env from '@/utils/env'
import XLSX from "xlsx"
import api from '@/api'
import axios from 'axios'
import Header from '@/components/Header.vue'
import EditInfo from "../components/EditInfo";
import AddMoreInfo from "../components/AddMoreInfo";
import EditPassword from "../components/EditPassword";
import logo from '@/assets/logo.png';
import zhi from '@/assets/zhi.png';
import yuan from '@/assets/yuan.png';
import {ElNotification, ElMessageBox} from 'element-plus'
import "@/assets/css/iconfont.css"

export default {
    name: 'HomePage',
    components: {
        AddMoreInfo,
        EditInfo,
        Header,
        EditPassword
    },
    data() {
        return {
            url: env[process.env.NODE_ENV].JSON_SITE,
            need_vip: false,
            remove_report_status: false,
            show_user_agreement: false,
            remind_status: false,
            show_vip_60_status: false,
            open_vip_60_btn_status: false,
            rank_status: false,
            collection_total: 0,
            remove_report_id: 0,
            vocation_collection_total: 0,
            art_collection_total: 0,
            pre_collection_total: 0,
            logo: logo,
            favorites_status: false,
            show_alert: true,
            favorites_index: 0,
            favorites_list: [],
            favorites_type: 1,
            major_remind_list: [],
            show_edit_info: 0,
            marks: [],
            upload_collection_list: [],
            score: 0,
            all_num: 0,
            top: 0,
            top_num: 0,
            middle_num: 0,
            bottom_num: 0,
            bottom: 0,
            label_num: [],
            dialogVisible: false,
            dialogMajorsVisible: false,
            activeName: 'first',
            listName: 'first',
            collection_type: 'collection_undergraduate',
            tags: [],
            favorite_list: [],
            art_favorite_list: [],
            majorsData: [],
            formLabelWidth: '120px',
            dialogFormVisible: true,
            provinces: [],
            types: [],
            schools: [],
            serial_number: ['', '', '', ''],
            major_one: [],
            major_two: [],
            major_three: [],
            major_three_list: [],
            majorse: [],
            school_list: [],
            level: [],
            plans: {},
            tableData: [],
            tableData_str: '加载中...',
            pre_tableData: [],
            pre_tableData_str: '加载中...',
            vocation_tableData: [],
            art_tableData: [],
            vocation_tableData_str: '加载中...',
            art_tableData_str: '加载中...',
            before_one: '',
            before_two: '',
            before_three: '',
            rank_list: [],
            score_rank_list: [],
            plan_rank: [],
            vip_level: 0,
            modify_score_times: 0,
            detailed_info_times: 0,
            collect_sort_type: false,
            show_history_year: [],
            system_tips_list: {
                1: {name: '等效分'},
                2: {name: '上段位变化量'},
                3: {name: '本校招生变化量'},
                4: {name: ''},
                5: {name: '位次'}
            },
            system_tips_1_name: '',
            system_tips_2_name: '',
            system_tips_3_name: '',
            captcha: '',
            phone: '',
            zhi: zhi,
            yuan: yuan,
            show_more_info: 0,
            show_pass_info: 0,
            active: 1,
            edit_info: false,
            approval_id: 0,
            chage_rank: 0,
            show_recommend_rank: 0,
            recommend_ranking: true,
            user_is_register: false,
            show_get_phone_code: true,
            majors: [
                {
                    'name': '物理',
                    'index': '1'
                },
                {
                    'name': '历史',
                    'index': '2'
                },
                {
                    'name': '生物',
                    'index': '3'
                },
                {
                    'name': '化学',
                    'index': '4'
                },
                {
                    'name': '地理',
                    'index': '5'
                },
                {
                    'name': '思想政治',
                    'index': '6'
                },
            ],
            branch: [
                {
                    'name': '',
                    'class_name': '',
                    'score_line': '',
                    'level': ''
                }
            ],
            high_branch: {
                class_name: '',
                level: 0,
                name: '',
                score_line: 0
            },
            low_branch: {
                class_name: '',
                level: 0,
                name: '',
                score_line: 0
            },
            base_info: {
                'class_id': 0,
                'score': 0,
                'name': '',
                'province': '0',
                'province_id': '0',
                'subject_one': 0,
                'subject_three': 0,
                'subject_two': 0,
                'subjects': "",
                'majors': [],
                'vip_60_need_card': 1,
                'vip_experience_times': 0,
                'vip_level': 0,
                'is_vip': 0,
                'can_be_modified': 0,
                'expires_in': '',
                'open_experience_vip': 1
            },
            group_name: '',
            group_id: 0,
            level_name: '',
            form: {
                name: '',
                province_id: '0',
                majors: [],
                score: 0,
                change_id: '',
                provinces: [],
                type: [],
                level: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                order_type: 1,
                score_rank: 0,
                score_range: 0,
            },
            vocation_form: {
                name: '',
                province_id: '0',
                majors: [],
                score: 0,
                change_id: '',
                provinces: [],
                type: [],
                level: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                order_type: 1,
                score_range: 0,
            },
            art_form: {
                name: '',
                province_id: '0',
                majors: [],
                score: 0,
                change_id: '',
                provinces: [],
                type: [],
                level: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                order_type: 1,
                score_range: 0,
            },
            pre_form: {
                name: '',
                province_id: '0',
                majors: [],
                batch: 'B',
                score: 0,
                change_id: '',
                provinces: [],
                type: [],
                level: [],
                school_ids: [],
                follow_major: [],
                major_one: 0,
                major_two: [],
                page_size: 10,
                total: 0,
                page: 1,
                order_type: 1,
                score_range: 0,
            },
            approval: [],
            submit_times: 0,
            approval_num: 0,
            approval_info: {
                approve_num: 0,
                instructor_id: 0,
                instructor_name: "",
                instructor_nickname: "",
                num: 0,
                times: 0,
            },
            approval_list: [],
            subjects_name: [],
            report_list: [],
            art_report_list: [],
            vocation_report_list: [],
            pre_report_list: [],
            agreement: '',
            show_agreement: 1,
            need_more_info: 0,
            user_status: 0,
            vocation_api: env[process.env.NODE_ENV].VOCATION_API,
            arts_api: env[process.env.NODE_ENV].ART_API,
            preb_api: env[process.env.NODE_ENV].PRE_API,
            show_majors_job_info: false,
            majors_job_info: [],
            majors_job_list: [],
            show_major_label: 0,
            submit_report: 0,
            upload_collection_batch: 5,
            upload_collection_status: false,
            upload_collection_type: 2,
        }
    },
    mounted() {
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (to.query.batch) {
                vm.collection_type = 'collection_pre'
                vm.pre_form.batch = to.query.batch || 'B'
            } else if (from.path.indexOf('chose_vocation') > -1) {
                vm.collection_type = 'collection_speciality'
            } else if (from.path.indexOf('chose_ahead') > -1) {
                vm.collection_type = 'collection_pre'
                vm.pre_form.batch = from.query.batch || 'B'
            } else if (from.path.indexOf('collection_art') > -1) {
                vm.collection_type = 'collection_art'
            }
            if (from.query.batch) {
                vm.pre_form.batch = from.query.batch || 'B'
            }
        });
    },
    created() {
        let that = this

        if (parseInt(this.user_status) === 1) {
            this.show_edit_info = 1
        }
        this.base_info = JSON.parse(localStorage.getItem('base_info'))
        if (this.base_info.subjects_name === undefined) {
            this.base_info.subjects_name = this.base_info.subjects.split('/')
        }

        this.group_name = localStorage.getItem('group_name')
        this.level_name = localStorage.getItem('level_name')

        this.user_info()
        this.favorites()
        this.get_agreement()
        this.get_approval_info()
        this.system_tips()
        this.get_approval_list()
        this.get_report_list()
        this.vocation_favorites()
        this.pre_favorites()
        this.get_vocation_report_list()
        // this.art_favorites()
        this.get_power()
        this.get_card_num()
        if (this.base_info.phone === '' || this.base_info.phone === 0 || this.base_info.phone.length < 2) {
            this.user_is_register = true
        } else {
            this.get_system_config()
        }
        this.branch = JSON.parse(localStorage.getItem('branch'))
    },
    methods: {
        chose_school(type = 'ben') {
            if (type === 'ben') {
                this.$router.push('/chose_school')
            } else if (type === 'vocation') {
                this.$router.push('/chose_vocation')
            } else {
                this.$router.push('/chose_ahead')
            }
        },
        errorCollectionHandleClose() {
            this.upload_collection_status = false
        },
        show_major_detail(major_name, major_code) {
            let page = this.$router.resolve({path: '/majors', query: {major_code: major_code, major_name: major_name}})
            window.open(page.href, '_blank')
        },
        majors_job_info_close() {
            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = []
            this.majors_job_list = []
        },
        show_majors_job(item) {
            let that = this

            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = item

            console.log(item)
            let param = []
            for (let i in item) {
                param.push(item[i]['code'])
            }

            if (param.length < 1) {
                return false
            }
            axios.post(
                api.open_major.majors_info, {'major_codes': param}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.majors_job_list = res.data.data
                }
            }).catch(function (err) {
            });
        },
        get_card_num() {
            let that = this
            axios.get(
                api.user.get_card_num, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.serial_number = res.data.data.serial_number.split('-')
                    console.log(that.serial_number)
                }
            }).catch(function (err) {
            });
        },
        get_power() {
            let that = this

            let teacher_login = localStorage.getItem("teacher_login")
            if(teacher_login && parseInt(teacher_login) === 1){
                that.show_major_label = 1
                that.submit_report = 1
                return false
            }

            axios.get(
                api.user.power, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.show_major_label = res.data.data.show_major_label
                    that.submit_report = res.data.data.submit_report
                }
            }).catch(function (err) {
            });
        },
        need_login(token) {
            let that = this
            axios.post(
                api.user.need_login, {token: token}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                if (res.data.code === 10000) {
                    clearInterval(that.timer);

                    localStorage.setItem('group_id', res.data.data.group_id)
                    localStorage.setItem('group_name', res.data.data.group_name)
                    localStorage.setItem('level_name', res.data.data.level_name)
                    localStorage.setItem('show_vip', res.data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    localStorage.setItem('name', res.data.data.name)
                    localStorage.setItem('jwt', res.data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(res.data.data.status))

                    that.$router.push('/home_page')
                } else if (res.data.code === 8888) {
                    //
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                console.log(err)
                console.log(err.response)
                clearInterval(that.timer);
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        search_param() {
            let that = this
            axios.get(api.user.search_param, {}).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    localStorage.setItem('search_param', JSON.stringify(res.data.data))
                }
            }).catch(function (err) {
            });
        },
        recommend_rank_click() {
            this.remind_status = false
            let that = this
            let recommend_ranking = 1
            if (this.recommend_ranking) {
                recommend_ranking = 0
            }
            axios.get(
                api.user.recommend_rank, {params: {'show_recommend_rank': recommend_ranking}}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.code)
                if (res.data.code === 10000) {

                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        confirm_agreement() {
            this.show_user_agreement = false
            this.$router.push('/goods')
        },
        get_agreement() {
            let that = this
            axios.get(
                api.user.get_agreement, {params: {}}
            ).then(function (res) {
                that.agreement = res.data.data.desc
                that.show_agreement = res.data.data.show_agreement
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        fatherMethod() {
            this.user_info()
            this.favorites()
            this.vocation_favorites()
            this.pre_favorites()
            // this.art_favorites()
            console.log('-----+++++---')
        },
        edit_info_fun() {
            if(this.base_info.is_vip == 1){
                this.show_more_info = true
            }
            this.user_info()
            this.favorites()
            this.vocation_favorites()
            this.pre_favorites()
            // this.art_favorites()
            console.log('-----+++++---')
        },
        batch_change() {
            this.pre_favorites()
        },
        get_history_year() {
            let that = this
            axios.get(
                api.system.config, {
                    params: {
                        type: [1]
                    }
                }
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.show_history_year = res.data.data.config.show_history_year
                    that.show_history_year = JSON.parse(that.show_history_year)
                    localStorage.setItem('show_history_year', res.data.data.config.show_history_year)
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        open_report(id) {
            console.log(id)
            this.$router.push('/report?id=' + id)
        },
        remove_report(id) {
            this.remove_report_id = id
            this.remove_report_status = true
        },
        confirm_remove_report() {
            let that = this
            this.remove_report_id += ''
            axios.post(
                api.user.remove_report, {report_id: this.remove_report_id}
            ).then(function (res) {
                console.log(res)
                console.log(res.data)
                console.log(res.data.code)
                if (res.data.code === 10000) {
                    that.remove_report_status = false
                    that.$notify({
                        title: '成功',
                        message: '操作成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.get_report_list()
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误!',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        reportHandleClose() {
            this.cannel_remove_report();
        },
        cannel_remove_report() {
            this.remove_report_id = 0
            this.remove_report_status = false
        },
        look_report(pdf) {
            window.open(pdf)
        },
        handleClose() {
        },
        open_experience_vip(type = 1) {
            if (type === 2) {
                this.show_vip_60_status = false
                this.send_open_experience_vip()
            } else {
                if (this.base_info.vip_60_need_card === 1) {
                    this.show_vip_60_status = true
                } else {
                    this.send_open_experience_vip()
                }
            }
        },
        send_open_experience_vip() {
            let that = this
            let card = (this.serial_number.join('-')).toUpperCase()
            console.log(card)
            if (card.length !== 19 && that.base_info.vip_60_need_card === 1) {
                that.$notify.error({
                    title: '错误',
                    message: '请输入正确的序列号!',
                    offset: 50,
                });
                return false
            }

            axios.post(
                api.user.open_experience_vip, {'card': card}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.$notify({
                        title: '成功',
                        message: '开通体验VIP成功!',
                        type: 'success',
                        offset: 50,
                    });
                    that.$router.go(0);
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {

            });
        },
        edit_info_click() {
            this.show_edit_info++
        },
        edit_pass_click() {
            this.show_pass_info++
        },
        sign_out() {
            localStorage.clear()
            let _this = this
            _this.$router.push('/')
        },
        goods_page() {
            this.show_user_agreement = true

        },
        show_more() {
            this.show_more_info++
        },
        get_vocation_report_list() {
            // user/
            let that = this
            axios.get(
                this.vocation_api + api.user.report_list, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.vocation_report_list = res.data.data
                }
            }).catch(function (err) {

            });
        },
        get_pre_report_list() {
            // user/
            let that = this
            axios.get(
                this.preb_api + api.user.report_list, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.pre_report_list = res.data.data
                }
            }).catch(function (err) {

            });
        },
        get_report_list() {
            // user/
            let that = this
            axios.get(
                api.user.report_list, {}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    that.report_list = res.data.data
                }
            }).catch(function (err) {

            });
        },
        get_phone_code() {
            let that = this
            let phone = this.phone.replace(/\s+/g, '')
            if (phone.length !== 11) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整手机号",
                    offset: 150,
                })
                return false
            }
            axios.post(
                api.user.get_phone_code, {
                    phone: phone
                }
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.show_get_phone_code = !that.show_get_phone_code
                } else {
                    ElNotification({
                        title: '友情提醒',
                        message: res.data.data.join(';'),
                        offset: 150,
                    })
                }
                console.log(res)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        send_phone_code() {
            let that = this
            let phone = this.phone.replace(/\s+/g, '')
            let captcha = this.captcha.replace(/\s+/g, '')
            if (phone.length !== 11) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整手机号",
                    offset: 150,
                })
                return false
            }
            if (captcha.length !== 6) {
                ElNotification({
                    title: '友情提醒',
                    message: "请输入完整验证码",
                    offset: 150,
                })
                return false
            }
            axios.post(
                api.user.send_phone_code, {
                    phone: phone,
                    captcha: captcha
                }
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(JSON.stringify(res.data.data.base_info))
                    that.user_is_register = !that.user_is_register
                    that.show_get_phone_code = !that.show_get_phone_code

                    that.branch = res.data.data.branch
                    that.base_info = res.data.data.base_info
                    that.group_name = res.data.data.group_name
                    that.level_name = res.data.data.level_name

                    localStorage.setItem('group_id', res.data.data.group_id)
                    localStorage.setItem('group_name', res.data.data.group_name)
                    localStorage.setItem('level_name', res.data.data.level_name)
                    localStorage.setItem('show_vip', res.data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    localStorage.setItem('name', res.data.data.name)
                    localStorage.setItem('jwt', res.data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(res.data.data.status))

                    if(parseInt(res.data.data.base_info.score) < 1){
                        that.show_edit_info = 1
                    }

                    that.$router.push('/home_page')
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        collect_sort_change() {
            let that = this
            axios.post(
                api.user.collect_sort_change, {collect_sort_type: this.collect_sort_type}
            ).then(function (res) {
                console.log(res)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        change_bind(approval_id) {
            let that = this
            axios.get(
                api.user.binding_approval, {
                    params: {
                        instructor_id: approval_id
                    }
                }
            ).then(function (res) {
                console.log(res)
                that.get_approval_info()
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },

        get_system_config() {
            let that = this
            axios.get(
                api.system.status, {}
            ).then(function (res) {
                console.log(res)
                that.show_vip = res.data.data.show_vip.value
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },

        step_one_click(e) {
            this.show_edit_info++
        },
        get_approval_list() {
            let that = this
            axios.get(
                api.user.approval_list, {
                    params: {
                        page: 1,
                        step: 10
                    }
                }
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                that.approval_list = res.data.data
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        get_approval_info() {
            let that = this
            axios.get(
                api.user.approval_info, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                that.approval_info = res.data.data
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },

        pending_approval() {
            let that = this
            axios.get(
                api.instructor.pending_approval, {}
            ).then(function (res) {
                console.log(res)
                that.approval = res.data.data
                for (let i in that.approval) {
                    if (that.approval[i]['approve_num'] < that.approval[i]['num']) {
                        that.approval_num++
                    }
                }
                localStorage.setItem('approval_num', that.approval_num)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        approval_item(member_id) {
            this.$router.push({
                path: '/approval',
                query: {
                    id: member_id
                }
            })
        },
        input_info() {
            console.log(this.form)
            let that = this
            axios.post(
                api.user.input, this.form
            ).then(function (res) {
                console.log(res)
                if (res.data.data.code === 10000) {
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    that.branch = res.data.data.branch
                    that.base_info = res.data.data.base_info
                    that.active = 2
                    that.$notify({
                        title: '成功',
                        message: '信息录入成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.user_info()
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        chose_major(id = 0) {
            let _this = this
            _this.$router.push({
                path: '/chose_school',
                query: {
                    id
                }
            })
        },
        system_tips() {
            let that = this
            let data = http.get(api.system_tips.list, {
                page: 1,
                step: 10,
                show_all: 1
            })
            data.then(data => {
                console.log(data.data)
                that.system_tips_list = data.data
            })
        },
        submit_approval() {
            this.rank_status = true
        },
        download_v_collection() {
            let that = this
            this.vocation_form.page = 1;
            this.vocation_form.page_size = 300;
            let obj = {
                ...this.vocation_form, 'download': 1
            }
            axios.post(
                this.vocation_api + api.user.favorites, obj
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    let list = res.data.data.data
                    console.log(list)
                    let result = [];
                    //if (that.vocation_form.order_type == 2) { // 推荐排序
                        result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '专业标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //} else {
                    //    result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //}
                    for (let i in list) {
                        let label_list = ''
                        if (list[i]['label_list'] !== undefined && list[i]['label_list'].length > 0) {
                            label_list = list[i]['label_list'].join(',  ')
                        }

                        let major_label = [];
                        if (list[i]['postgraduate_major_str'].length > 0) {
                            major_label.push(list[i]['postgraduate_major_str'])
                        }
                        if (list[i]['doctor_major_str'].length > 0) {
                            major_label.push(list[i]['doctor_major_str'])
                        }
                        if (list[i]['discipline_ranking_major_str'].length > 0) {
                            major_label.push(list[i]['discipline_ranking_major_str'])
                        }
                        if (list[i]['first_class_major_str'].length > 0) {
                            major_label.push(list[i]['first_class_major_str'])
                        }

                        let data = [];
                        data.push((list[i]['school_code'] || '') + "\t")
                        data.push(list[i]['school_name'])
                        data.push((list[i]['major_num'] || '') + "\t")
                        data.push(list[i]['majors_name'])
                        data.push(list[i]['match_str'])
                        data.push(list[i]['nature_str'])
                        data.push(list[i]['type_str'])
                        data.push(label_list)
                        //if (that.vocation_form.order_type == 2) {
                            data.push(major_label.join(',  '))
                        //}
                        data.push(list[i]['forecast_low_score'])
                        data.push(list[i]['num'])
                        data.push(list[i]['length'] || '')
                        data.push(list[i]['fees'])
                        data.push(that.replace_html(list[i]['remarks']))
                        result.push(data);
                    }
                    console.log('done...')
                    let workbook = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.aoa_to_sheet(result);
                    XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
                    XLSX.writeFile(workbook, "我收藏的志愿.xls"); //导出Excel
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: '请求错误 请稍后再试',
                    offset: 50,
                });
            });
        },
        download_a_collection() {
            let that = this
            this.art_form.order_type = 2
            this.art_form.page = 1;
            this.art_form.page_size = 300;
            let obj = {
                ...this.art_form
            }
            axios.post(
                this.arts_api + api.user.favorites, obj
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    let list = res.data.data.data
                    console.log(list)
                    let result = [];
                    //if (that.art_form.order_type == 2) { // 推荐排序
                        result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '专业标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //} else {
                    //    result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //}
                    for (let i in list) {
                        let label_list = ''
                        if (list[i]['label_list'] !== undefined && list[i]['label_list'].length > 0) {
                            label_list = list[i]['label_list'].join(',  ')
                        }

                        let major_label = [];
                        if (list[i]['postgraduate_major_str'].length > 0) {
                            major_label.push(list[i]['postgraduate_major_str'])
                        }
                        if (list[i]['doctor_major_str'].length > 0) {
                            major_label.push(list[i]['doctor_major_str'])
                        }
                        if (list[i]['discipline_ranking_major_str'].length > 0) {
                            major_label.push(list[i]['discipline_ranking_major_str'])
                        }
                        if (list[i]['first_class_major_str'].length > 0) {
                            major_label.push(list[i]['first_class_major_str'])
                        }

                        let data = [];
                        data.push((list[i]['school_code'] || '') + "\t")
                        data.push(list[i]['school_name'])
                        data.push((list[i]['major_num'] || '') + "\t")
                        data.push(list[i]['majors_name'])
                        data.push(list[i]['match_str'])
                        data.push(list[i]['nature_str'])
                        data.push(list[i]['type_str'])
                        data.push(label_list)
                        //if (that.art_form.order_type == 2) {
                            data.push(major_label.join(',  '))
                        //}
                        data.push(list[i]['forecast_low_score'])
                        data.push(list[i]['num'])
                        data.push(list[i]['length'] || '')
                        data.push(list[i]['fees'])
                        data.push(that.replace_html(list[i]['remarks']))
                        result.push(data);
                    }
                    console.log('done...')
                    let workbook = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.aoa_to_sheet(result);
                    XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
                    XLSX.writeFile(workbook, "我收藏的志愿.xls"); //导出Excel
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: '请求错误 请稍后再试',
                    offset: 50,
                });
            });
        },
        replace_html(str) {
            return str.replace(/(<([^>]+)>)/ig, "");
        },
        download_p_collection() {
            let that = this
            this.pre_form.page = 1;
            this.pre_form.page_size = 300;
            let obj = {
                ...this.pre_form, 'download': 1
            }
            axios.post(
                this.preb_api + api.user.favorites, obj
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    let list = res.data.data.data
                    console.log(list)
                    let result = [];
                    //if (that.pre_form.order_type == 2) { // 推荐排序
                        result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '专业标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //} else {
                    //    result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //}
                    for (let i in list) {
                        let label_list = ''
                        if (list[i]['label_list'] !== undefined && list[i]['label_list'].length > 0) {
                            label_list = list[i]['label_list'].join(',  ')
                        }

                        let major_label = [];
                        if (list[i]['postgraduate_major_str'].length > 0) {
                            major_label.push(list[i]['postgraduate_major_str'])
                        }
                        if (list[i]['doctor_major_str'].length > 0) {
                            major_label.push(list[i]['doctor_major_str'])
                        }
                        if (list[i]['discipline_ranking_major_str'].length > 0) {
                            major_label.push(list[i]['discipline_ranking_major_str'])
                        }
                        if (list[i]['first_class_major_str'].length > 0) {
                            major_label.push(list[i]['first_class_major_str'])
                        }

                        let data = [];
                        data.push((list[i]['school_code'] || '') + "\t")
                        data.push(list[i]['school_name'])
                        data.push((list[i]['major_num'] || '') + "\t")
                        data.push(list[i]['majors_name'])
                        data.push(list[i]['match_str'])
                        data.push(list[i]['nature_str'])
                        data.push(list[i]['type_str'])
                        data.push(label_list)
                        //if (that.pre_form.order_type == 2) {
                            data.push(major_label.join(',  '))
                        //}
                        data.push(list[i]['forecast_low_score'])
                        data.push(list[i]['num'])
                        data.push(list[i]['length'] || '')
                        data.push(list[i]['fees'])
                        data.push(that.replace_html(list[i]['remarks']))
                        result.push(data);
                    }
                    console.log('done...')
                    let workbook = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.aoa_to_sheet(result);
                    XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
                    XLSX.writeFile(workbook, "我收藏的志愿.xls"); //导出Excel
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: '请求错误 请稍后再试',
                    offset: 50,
                });
            });
        },
        collection_demo() {
            let that = this
            let result = [];
            result.push(['院校代号', '专业代号']);
            result.push(['0001', '01']);
            let workbook = XLSX.utils.book_new();
            let ws1 = XLSX.utils.aoa_to_sheet(result);
            XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
            XLSX.writeFile(workbook, "下载导入收藏志愿样表.xls"); //导出Excel
        },
        upload_collection(type) {
            this.upload_collection_type = parseInt(type)
            document.querySelector("#file").click();
        },
        upload_file(event) {
            let inputDom = event.target
            let file = event.target.files[0];

            this.formData = new FormData();
            this.formData.append("file", file, file.name);

            let api_url = ''
            if (this.upload_collection_type === 1) {
                if (this.pre_form.batch === 'A') {
                    this.formData.append("batch_id", '1');
                } else {
                    this.formData.append("batch_id", '2');
                }
                api_url = this.preb_api
            } else if (this.upload_collection_type === 3) {
                api_url = this.vocation_api
            }

            let that = this;
            axios
                .post(api_url + api.user.upload_collection, this.formData)
                .then(function (res) {
                    console.log(res)
                    if (res.data.code === 10000) {
                        that.upload_collection_status = true
                        that.upload_collection_list = res.data.data
                        that.$notify({
                            title: '成功',
                            message: '信息录入成功',
                            type: 'success',
                            offset: 50,
                        });
                    } else {
                        that.$notify.error({
                            title: '错误',
                            message: res.data.data.msg,
                            offset: 50,
                        });
                    }
                    inputDom.value = ''

                    that.favorites()
                })
                .catch(function (err) {
                    if (err.response) {
                        console.log(err.response.data);
                    } else {
                        console.log(err.message);
                    }
                    inputDom.value = ''
                });
        },
        tableRowClassName(info) {
            if (1 !== parseInt(info.row.result)) {
                return 'warning-row'
            }
            return ''
        },
        download_error_collection() {
            let that = this
            let result = [];
            result.push(['院校代号', '专业代号', '结果']);
            for (let i in that.upload_collection_list) {
                let data = [];
                if (parseInt(that.upload_collection_list[i]['result']) !== 1) {
                    data.push(that.upload_collection_list[i]['school_code'])
                    data.push(that.upload_collection_list[i]['major_num'])
                    data.push(that.upload_collection_list[i]['result_str'])
                    result.push(data);
                }
            }
            console.log('done...')
            let workbook = XLSX.utils.book_new();
            let ws1 = XLSX.utils.aoa_to_sheet(result);
            XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
            XLSX.writeFile(workbook, "导入收藏志愿-错误列表.xls"); //导出Excel
        },
        download_collection() {
            let that = this
            this.form.page = 1;
            this.form.page_size = 300;
            let obj = {
                ...this.form, 'download': 1
            }
            axios.post(
                api.user.favorites, obj
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    let list = res.data.data.data
                    console.log(list)
                    let result = [];
                    //if (that.form.order_type == 2) { // 推荐排序
                        result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '专业标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //} else {
                    //    result.push(['院校代号', '院校名称', '专业代号', '专业名称', '预测类型', '院校性质', '院校类型', '院校标签', '等效分', '招生人数', '学年', '学费', '备注']);
                    //}
                    for (let i in list) {
                        let label_list = ''
                        if (list[i]['label_list'] !== undefined && list[i]['label_list'].length > 0) {
                            label_list = list[i]['label_list'].join(',  ')
                        }

                        let major_label = [];
                        if (list[i]['postgraduate_major_str'].length > 0) {
                            major_label.push(list[i]['postgraduate_major_str'])
                        }
                        if (list[i]['doctor_major_str'].length > 0) {
                            major_label.push(list[i]['doctor_major_str'])
                        }
                        if (list[i]['discipline_ranking_major_str'].length > 0) {
                            major_label.push(list[i]['discipline_ranking_major_str'])
                        }
                        if (list[i]['first_class_major_str'].length > 0) {
                            major_label.push(list[i]['first_class_major_str'])
                        }

                        let data = [];
                        data.push((list[i]['school_code'] || '') + "\t")
                        data.push(list[i]['school_name'])
                        data.push((list[i]['major_num'] || '') + "\t")
                        data.push(list[i]['majors_name'])
                        data.push(list[i]['match_str'])
                        data.push(list[i]['nature_str'])
                        data.push(list[i]['type_str'])
                        data.push(label_list)
                        //if (that.form.order_type == 2) {
                            data.push(major_label.join(',  '))
                        //}
                        data.push(list[i]['forecast_low_score'])
                        data.push(list[i]['num'])
                        data.push(list[i]['length'] || '')
                        data.push(list[i]['fees'])
                        data.push(that.replace_html(list[i]['remarks']))
                        result.push(data);
                    }
                    console.log('done...')
                    let workbook = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.aoa_to_sheet(result);
                    XLSX.utils.book_append_sheet(workbook, ws1, "Sheet1");
                    XLSX.writeFile(workbook, "我收藏的志愿.xls"); //导出Excel
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: '请求错误 请稍后再试',
                    offset: 50,
                });
            });
        },
        approval_report() {
            if (this.collection_total === 0) {
                ElMessageBox.alert('请到选志愿板块，选择志愿', '提醒', {
                    confirmButtonText: '好的',
                    callback: () => {
                    },
                })
                return false
            }
            this.rank_status = false

            let that = this
            axios.get(
                api.user.send_2_instructor, {
                    params: {
                        type: 2
                    }
                }
            ).then(function (res) {
                console.log(res)
                console.log(res.data.code)
                if (res.data.code === 10000) {
                    that.submit_times--
                    ElNotification({
                        title: '操作成功',
                        message: '您选择的志愿已经提交审核',
                        duration: 0,
                        offset: 50,
                    })
                    that.get_report_list()
                } else {
                    ElNotification({
                        title: '操作失败',
                        message: res.data.data.join(''),
                        duration: 0,
                        offset: 50,
                    })
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        school_info(school_id, school_name, from = 1) {
            let page = this.$router.resolve({
                path: '/school_info',
                query: {
                    school_id: school_id,
                    school_name: school_name,
                    from: from,
                    batch: this.pre_form.batch
                }
            })

            window.open(page.href, '_blank')
        },
        school_map(school_name) {
            //this.$router.push({path: '/school_map', query: {school_name: school_name}})

            const routeData = this.$router.resolve({path: '/school_map', query: {school_name: school_name}})
            window.open(routeData.href, '_blank')
        },
        user_info() {
            let that = this
            axios.post(
                api.user.info, {}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data.status)
                console.log(res.data.data.base_info)
                if (res.data.data.base_info.score == '0') {
                    that.show_edit_info = 1
                } else if (res.data.data.base_info.vip_level >= 60 &&
                    res.data.data.base_info.need_more_info === 1) {
                    that.show_more_info++
                }

                that.group_id = res.data.data.group_id
                that.group_name = res.data.data.group_name
                that.level_name = res.data.data.level_name
                if (parseInt(res.data.data.base_info.collect_sort_type) === 1) {
                    that.collect_sort_type = false
                } else {
                    that.collect_sort_type = true
                }
                that.submit_times = res.data.data.base_info.submit_times
                that.form.score_rank = res.data.data.base_info.favorites_sort
                that.subjects_name = res.data.data.base_info.subjects_name
                that.need_more_info = res.data.data.base_info.need_more_info
                that.modify_score_times = parseInt(res.data.data.base_info.modify_score_times)
                that.detailed_info_times = parseInt(res.data.data.base_info.detailed_info_times)
                if (res.data.data.base_info.show_recommend_rank == 1) {
                    that.recommend_ranking = false
                } else {
                    that.recommend_ranking = true
                }
                console.log('-------------------------------')
                console.log(that.recommend_ranking)
                that.vip_level = res.data.data.base_info.vip_level
                that.base_info = res.data.data.base_info
                that.user_status = res.data.data.status
                localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                localStorage.setItem('group_name', res.data.data.group_name)
                localStorage.setItem('level_name', res.data.data.level_name)
                localStorage.setItem('submit_times', res.data.data.base_info.submit_times)
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },

        open_vip() {
            let _this = this
            _this.$router.push('/goods')
        },
        collection_order_click(e) {
            this.form.order_type = 1
            this.tableData = []
            this.form.page = 1;
            this.favorites()
        },
        collection_v_order_click(e) {
            this.vocation_form.order_type = 1
            this.vocation_tableData = []
            this.vocation_form.page = 1;
            this.vocation_favorites()
        },
        collection_a_order_click(e) {
            this.art_form.order_type = 1
            this.art_form = []
            this.art_form.page = 1;
            // this.art_favorites()
        },
        collection_p_order_click(e) {
            this.pre_form.order_type = 1
            this.pre_tableData = []
            this.pre_form.page = 1;
            this.pre_favorites()
        },
        show_recommend() {
            this.remind_status = true
        },
        score_rank_change(e){
            console.log(e)
            this.form.score_rank = parseInt(e)
            this.form.order_type = 2
            this.tableData = []
            this.form.page = 1;
            if (this.recommend_ranking === false) {
                this.remind_status = true
            }
            this.favorites()
        },
        score_order_click(e) {
            console.log(e)
            this.form.score_rank = parseInt(e)
            this.form.order_type = 2
            this.tableData = []
            this.form.page = 1;
            if (this.recommend_ranking === false) {
                this.remind_status = true
            }
            this.favorites()
        },
        score_v_order_click(e) {
            this.vocation_form.order_type = 2
            this.vocation_tableData = []
            this.vocation_form.page = 1;
            if (this.recommend_ranking === false) {
                this.remind_status = true
            }
            this.vocation_favorites()
        },
        score_p_order_click(e) {
            this.pre_form.order_type = 2
            this.pre_tableData = []
            this.pre_form.page = 1;
            if (this.recommend_ranking === false) {
                this.remind_status = true
            }
            this.pre_favorites()
        },
        handleSizeChange(val) {
            this.form.page_size = val
            this.favorites()
            console.log(`每页 ${val} 条`);
        },
        handlevSizeChange(val) {
            this.vocation_form.page_size = val
            this.vocation_favorites()
            console.log(`每页 ${val} 条`);
        },
        handleaSizeChange(val) {
            this.art_form.page_size = val
            // this.art_favorites()
            console.log(`每页 ${val} 条`);
        },
        handlepSizeChange(val) {
            this.pre_form.page_size = val
            this.pre_favorites()
            console.log(`每页 ${val} 条`);
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleCurrentChange(val) {
            this.form.page = val
            this.favorites()
            console.log(`当前页: ${val}`);
        },
        handlevCurrentChange(val) {
            this.vocation_form.page = val
            this.vocation_favorites()
            console.log(`当前页: ${val}`);
        },
        handleaCurrentChange(val) {
            this.art_form.page = val
            // this.art_favorites()
            console.log(`当前页: ${val}`);
        },
        handlepCurrentChange(val) {
            this.pre_form.page = val
            this.pre_favorites()
            console.log(`当前页: ${val}`);
        },
        continue_favorite() {
            let index = this.favorites_index
            let list = this.favorites_list
            let type = this.favorites_type

            let data = http.post(api.user.favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.tableData[index]['collection'] = 1
                    } else {
                        this.major_list[list['school_id']][index]['collection'] = 1
                    }
                    this.$notify({
                        title: '成功',
                        message: '选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.favorites_status = false
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                    this.favorites_status = false
                }
            })
        },
        favorite(index, list, type = 1) {
            if (list.collection === 1) {
                this.un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorites_index = index
                    this.favorites_list = list
                    this.favorites_type = type
                    this.favorites_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []

                    let data = http.post(api.user.favorite, {
                        "favorite_id": list.plan_id
                    })
                    data.then(data => {
                        console.log(data)
                        console.log(data.data.sum)
                        if (data.code === 10000) {
                            this.tableData[index]['collection'] = 1
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            console.log(list.id)
        },
        un_favorite(index, list, type = 1) {
            let that = this
            console.log(list.id)
            let data = http.post(api.user.un_favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.tableData[index]['collection'] = 0
                    } else {
                        this.majorsData[index]['collection'] = 0
                    }
                    that.favorites()
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        v_favorite(index, list, type = 1) {
            if (list.collection === 1) {
                this.v_un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorites_index = index
                    this.favorites_list = list
                    this.favorites_type = type
                    this.favorites_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []

                    let data = http.post(this.vocation_api + api.user.favorite, {
                        "favorite_id": list.plan_id
                    })
                    data.then(data => {
                        console.log(data)
                        console.log(data.data.sum)
                        if (data.code === 10000) {
                            this.vocation_tableData[index]['collection'] = 1
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            console.log(list.id)
        },
        a_favorite(index, list, type = 1) {
            if (list.collection === 1) {
                this.a_un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorites_index = index
                    this.favorites_list = list
                    this.favorites_type = type
                    this.favorites_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []

                    let data = http.post(this.arts_api + api.user.favorite, {
                        "favorite_id": list.plan_id
                    })
                    data.then(data => {
                        console.log(data)
                        console.log(data.data.sum)
                        if (data.code === 10000) {
                            this.vocation_tableData[index]['collection'] = 1
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            console.log(list.id)
        },
        p_favorite(index, list, type = 1) {
            if (list.collection === 1) {
                this.p_un_favorite(index, list, type)
            } else {
                if (list.major_remind_desc !== null && list.major_remind_desc.length > 0) {
                    this.favorites_index = index
                    this.favorites_list = list
                    this.favorites_type = type
                    this.favorites_status = true
                    this.major_remind_list = list.major_remind_desc
                } else {
                    this.major_remind_list = []

                    let data = http.post(this.preb_api + api.user.favorite, {
                        "favorite_id": list.plan_id
                    })
                    data.then(data => {
                        console.log(data)
                        console.log(data.data.sum)
                        if (data.code === 10000) {
                            this.pre_tableData[index]['collection'] = 1
                            this.$notify({
                                title: '成功',
                                message: '选择成功',
                                type: 'success',
                                offset: 50,
                            });
                        } else {
                            this.$notify.error({
                                title: '错误',
                                message: data.data.msg,
                                offset: 50,
                            });
                        }
                    })
                }
            }
            console.log(list.id)
        },
        a_un_favorite(index, list, type = 1) {
            let that = this
            console.log(list.id)
            let data = http.post(this.arts_api + api.user.un_favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.art_tableData[index]['collection'] = 0
                    } else {
                        this.majorsData[index]['collection'] = 0
                    }
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.vocation_favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        v_un_favorite(index, list, type = 1) {
            let that = this
            console.log(list.id)
            let data = http.post(this.vocation_api + api.user.un_favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.vocation_tableData[index]['collection'] = 0
                    } else {
                        this.majorsData[index]['collection'] = 0
                    }
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.vocation_favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        p_un_favorite(index, list, type = 1) {
            let that = this
            console.log(list.id)
            let data = http.post(this.preb_api + api.user.un_favorite, {
                "favorite_id": list.id
            })
            data.then(data => {
                console.log(data)
                console.log(data.data)
                if (data.code === 10000) {
                    if (type === 1) {
                        this.pre_tableData[index]['collection'] = 0
                    } else {
                        this.pre_tableData[index]['collection'] = 0
                    }
                    this.$notify({
                        title: '成功',
                        message: '取消选择成功',
                        type: 'success',
                        offset: 50,
                    });
                    that.pre_favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        order_change(plan_id, rank) {
            let old_id = 0;
            let new_id = 0;
            for (let i in this.rank_list) {
                console.log(this.rank_list[i])
                if (this.rank_list[i]['plan_id'] == plan_id) {
                    old_id = this.rank_list[i]['id']
                }
                if (this.rank_list[i]['rank'] == rank) {
                    new_id = this.rank_list[i]['id']
                }
            }

            let _this = this
            let data = http.post(api.favorite.change, {
                "new_id": new_id,
                "old_id": old_id,
                'type': 1
            })
            data.then(data => {
                console.log(data)
                if (data.code === 10000) {
                    _this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.tableData = []
                    this.favorites()
                } else {
                    _this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        score_order_change(plan_id, new_rank) {
            let old_rank = 0;
            let old_id = 1;
            for (let i in this.tableData) {
                if (this.tableData[i]['plan_id'] == plan_id) {
                    old_rank = this.tableData[i]['collation']
                    old_id = this.tableData[i]['id']
                }
            }
            let data = http.post(api.favorite.change, {
                "old_rank": old_rank,
                "new_rank": new_rank,
                "new_id": 1,
                "old_id": old_id,
                'type': 2
            })
            data.then(data => {
                console.log(data)
                if (data.code === 10000) {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.tableData = []
                    this.favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        score_v_order_change(plan_id, new_rank) {
            let old_rank = 0;
            let old_id = 1;
            for (let i in this.vocation_tableData) {
                if (this.vocation_tableData[i]['plan_id'] == plan_id) {
                    old_rank = this.vocation_tableData[i]['collation']
                    old_id = this.vocation_tableData[i]['id']
                }
            }
            let data = http.post(this.vocation_api + api.favorite.change, {
                "old_rank": old_rank,
                "new_rank": new_rank,
                "new_id": 1,
                "old_id": old_id,
                'type': 2
            })
            data.then(data => {
                console.log(data)
                if (data.code === 10000) {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.vocation_tableData = []
                    this.vocation_favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        score_p_order_change(plan_id, new_rank) {
            let old_rank = 0;
            let old_id = 1;
            for (let i in this.pre_tableData) {
                if (this.pre_tableData[i]['plan_id'] == plan_id) {
                    old_rank = this.pre_tableData[i]['collation']
                    old_id = this.pre_tableData[i]['id']
                }
            }
            let data = http.post(this.preb_api + api.favorite.change, {
                "old_rank": old_rank,
                "new_rank": new_rank,
                "new_id": 1,
                "old_id": old_id,
                'type': 2,
                'batch': this.pre_form.batch
            })
            data.then(data => {
                console.log(data)
                if (data.code === 10000) {
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success',
                        offset: 50,
                    });
                    this.pre_tableData = []
                    this.pre_favorites()
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                }
            })
        },
        vocation_favorites() {
            let obj = {
                ...this.vocation_form
            }
            let data = http.post(this.vocation_api + api.user.favorites, obj)
            data.then(data => {
                this.vocation_form.page = data.data.current_page
                this.vocation_form.total = parseInt(data.data.total)
                this.vocation_form.page_size = data.data.per_page
                this.vocation_collection_total = data.data.total

                this.vocation_favorite_list = []
                for (let i = 1; i <= this.vocation_form.total; i++) {
                    this.vocation_favorite_list.push({
                        'value': i,
                        'label': i
                    })
                }
                this.vocation_tableData = data.data.data
                if(this.vocation_tableData == undefined){
                    this.vocation_tableData = []
                }
                if (this.vocation_tableData.length < 1) {
                    this.vocation_tableData_str = '去选择志愿'
                }
            })
        },
        art_favorites() {
            let obj = {
                ...this.vocation_form
            }
            let data = http.post(this.arts_api + api.user.favorites, obj)
            data.then(data => {
                this.art_form.page = data.data.current_page
                this.art_form.total = parseInt(data.data.total)
                this.art_form.page_size = data.data.per_page
                this.art_collection_total = data.data.total

                this.art_favorite_list = []
                for (let i = 1; i <= this.vocation_form.total; i++) {
                    this.art_favorite_list.push({
                        'value': i,
                        'label': i
                    })
                }
                this.art_tableData = data.data.data
                if (this.art_tableData && this.art_tableData.length < 1) {
                    this.art_tableData_str = '去选择志愿'
                }
            })
        },
        pre_favorites() {
            let obj = {
                ...this.pre_form
            }
            let data = http.post(this.preb_api + api.user.favorites, obj)
            data.then(data => {
                this.pre_form.page = data.data.current_page
                this.pre_form.total = parseInt(data.data.total)
                this.pre_form.page_size = data.data.per_page
                this.pre_collection_total = data.data.total

                this.pre_favorite_list = []
                for (let i = 1; i <= this.pre_form.total; i++) {
                    this.pre_favorite_list.push({
                        'value': i,
                        'label': i
                    })
                }
                this.pre_tableData = data.data.data
                if(this.pre_tableData == undefined){
                    this.pre_tableData = []
                }
                if (this.pre_tableData.length < 1) {
                    this.pre_tableData_str = '去选择志愿'
                }
            })
        },
        favorites() {
            let obj = {
                ...this.form
            }
            let data = http.post(api.user.favorites, obj)
            data.then(data => {
                this.form.page = data.data.current_page
                this.form.total = parseInt(data.data.total)
                this.form.page_size = data.data.per_page
                this.collection_total = data.data.total

                this.favorite_list = []
                for (let i = 1; i <= this.form.total; i++) {
                    this.favorite_list.push({
                        'value': i,
                        'label': i
                    })
                }
                this.tableData = data.data.data
                if (this.tableData.length < 1) {
                    this.tableData_str = '去选择志愿'
                }
            })
        },
        getPlanInfo(plan_id = 0, list) {
            let data = http.get(this.url + 'plan/' + plan_id + '.json', {})
            data.then(data => {
                this.plans[plan_id] = data
                if (list.length > 0) {
                    this.tableData = list
                }
            })
        }
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.btn1s:hover {
    background-color: #C23B1E;
    color: #FFFFFF;
    border: 0;
}

:root {
    --el-color-primary: #FFFFFF;
    --el-color-primary-light-9: #C23B1E;
    border: 0;

}

.btn1s.el-button--primary {
    --el-button-background-color: #C23B1E;
}

.btn_default {
    background-color: #C23B1E;
    color: #FFFFFF;
}

/* -----------志愿------------------ */
#top_con {
    position: sticky;
    top: 0px;
    background-color: #ffffff;
    z-index: 99;

}

.div_nav a {
    margin-left: 50px;
}

.box-card {
    border: 0;
    margin-top: 15px;
}

.box-card >>> .el-card__body {
    padding: 0;
}

.box-card >>> .el-card__header {
    padding: 0;
}

.major_sprint {
    display: inline-block;
    border-top: 60px solid #F3AB50;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_floors {
    display: inline-block;
    border-top: 60px solid #24C599;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_stable {
    display: inline-block;
    border-top: 60px solid #4D94F1;
    border-right: 18px solid transparent;
    height: 0;
    width: 40px;
}

.major_info_desc {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 12px;
}

.major_remarks {
    background-color: #F8F8F8;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 30px;
    line-height: 30px;
    text-align: left;
    font-size: 12px;
}

.school_info {
    margin-top: 30px;
    float: left;
    height: 285px;
    width: 40%;
    border-right: 1px #EBEBEB solid;
}

.majors_list {
    float: right;
    margin-top: 30px;
    height: 285px;
    width: 57%;
    overflow-y: scroll;
    scrollbar-color: #DBDBDB #F8F8F8;
    /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin;
    /* 滚动条宽度有三种：thin、auto、none */
}

.major_list {
    float: left;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    width: 57%;
    height: 230px;
    overflow-y: auto;
}

.majors_list::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
}

.majors_list::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: skyblue;
    background-image: -webkit-linear-gradient(#DBDBDB, #DBDBDB);
}

.majors_list::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(248, 248, 248, 1);
    background: #ededed;
    border-radius: 10px;
}

.school_tag {
    text-align: left;
    margin-top: 10px;
}

.school_tag >>> .el-tag--light {
    margin-left: 0px;
    margin-right: 5px;
}

/*.el-checkbox >>> .el-checkbox__input {*/
/*    display: none;*/
/*}*/

.el-form-item >>> .el-form-item__label {
    line-height: 30px;
}

.el-form-item >>> .el-form-item__content {
    line-height: 30px;
    margin-left: 35px;
}

/*.el-radio >>> .el-radio__input {*/
/*    display: none;*/
/*}*/

.selected {
    color: #d01414;
    font-size: 24px;
    height: 60px;
    line-height: 60px;
    border-bottom: 1px red solid;
    cursor: pointer
}

.unselected {
    color: #919090;
    font-size: 20px;
    cursor: pointer
}

.container {
    background-color: #FFFFFF;
    width: 1230px;
    margin: 70px auto 0;
}

.left_content {
    float: left;
    width: 55%;
}

.left_content_img {
    width: 100%;
    margin-top: 124px;
}

.right_content {
    float: right;
    width: 40%;
    margin-top: 124px;
}

.div_logo {
    justify-content: flex-start;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 100px;
}

.name_logo {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
}

.div_nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.div_nav_a {
    font-family: 'Microsoft YaHei';
    text-decoration: none;
    cursor: pointer;
}

.div_nav a {
    margin-left: 50px;
}

.zhi_img {
    width: 48%;
    min-height: 230px;
    float: left;
    border-radius: 5px;
}

.yuan_img {
    width: 48%;
    min-height: 230px;
    float: right;
    border-radius: 5px;
}

.no_collection {
    height: 60px;
    width: 100%;
    background-color: #F2F2F2;
}

.collecting {
    height: 60px;
    width: 100%;
    background-color: #C23B1E;
}

.el-card >>> .el-card__header {
    padding: 0 20px;
}

.zhiyuanList {
    width: 1200px;
}

.card_btn {
    margin: 20px auto 0;
    width: 600px;
    height: 25px;
    background-image: linear-gradient(to right, #FFD2AA 0%, #fff 120%);
    font-size: 14px;
    text-align: center;
    border-radius: 20px;
    line-height: 25px;
    font-weight: inherit;
}

.upload-demo {
    display: inline-block;
}

.upload-demo .el-button {
    padding: 0;
    margin: 0 auto;
    height: 30px;
    min-height: 30px;
    line-height: 30px;
    width: 120px;
    font-size: 14px;
}

.el-table .warning-row {
    --el-table-tr-bg-color: red;
}
</style>
<style>
.el-checkbox__input.is-checked + .el-checkbox__label {
    color: #C23B1E;
}

.el-table .warning-row {
    --el-table-tr-bg-color: red;
}
</style>