<template>
  <el-dialog title="修改密码" v-model="edit_info" @closed="close_edit_info">
    <el-form :model="base_info" style="margin: 20px;">
      <div class="step_one_content">
        <el-form-item label="原始密码">
          <el-input v-model="base_info.old_password" show-password style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="新改密码">
          <el-input v-model="base_info.new_password" show-password style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="base_info.correction" show-password style="width: 300px;"></el-input>
        </el-form-item>

      </div>
    </el-form>
    <template #footer>
    <span class="dialog-footer">
      <el-button @click="edit_info = false">取 消</el-button>
      <el-button type="primary" @click="submit_info">确 定</el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>
import api from '@/api'
import axios from "axios";

export default {
  name: 'EditPassword',
  props: {
    show: Number
  },
  data() {
    return {
      edit_info: false,
      base_info: {
        'old_password': '',
        'new_password': '',
        'correction': '',
      },
    }
  },
  watch: {
    show(new_val) {
      this.edit_info = new_val > 0;
    }
  },
  mounted() {
  },
  created() {
    this.base_info = JSON.parse(localStorage.getItem('base_info'))
    this.edit_info = this.show > 0
  },
  methods: {
    close_edit_info(e) {
      this.edit_info = false
    },
    submit_info() {
      this.edit_info = false
      let that = this
      console.log(this.base_info)

      axios.post(
          api.user.change_password, this.base_info
      ).then(function (res) {
        if (res.data.data.code === 10000) {
          that.active = 2
          that.$notify({
            title: '成功',
            message: '信息录入成功',
            type: 'success',
              offset: 50,
          });
          that.$router.go(0);
        } else {
          that.$notify.error({
            title: '错误',
            message: res.data.data.msg,
              offset: 50,
          });
        }
      }).catch(function (err) {
        that.$notify.error({
          title: '错误',
          message: err.response.data.msg,
            offset: 50,
        });
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
