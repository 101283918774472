<template>
    <div v-loading="is_loading" element-loading-text="自动登陆中...">
        <article class="container">
            <div>
                <div class="left_content">
                    <img class="left_content_img" :src="login_scene" alt="老师">
                </div>
                <div style="margin-top:200px;">
                    <el-tabs class="demo-tabs" v-model="activeName">
                        <el-tab-pane label="微信扫码登录" name="first">
                            <div style="margin: 20px auto 0;min-height: 260px;" v-if="show_qr_code_login">
                                <div style="margin: 20px auto 0;position: relative;width:200px;">
                                    <img :src="ticket_url" style="width:200px;height:200px;">
                                    <div
                                        style="position:absolute;top: 55px;height: 90px;background-color: #fafafa;width:200px;">
                                        <div style="height: 90px;line-height: 90px;" @click="reload_qr_code">
                                            <span style="cursor:pointer">点击登录</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <!--                        <el-tab-pane label="其他登录方式" name="second">-->
                        <!--                            <div style="margin-top: 30px;min-height: 260px;"></div>-->
                        <!--                        </el-tab-pane>-->
                    </el-tabs>
                </div>
            </div>
        </article>
        <!--        <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 85px;">-->
        <!--            <div style="color: #999999 ;text-align: center;padding-top: 60px;">-->
        <!--                Copyright @ 2011-2021 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>-->
        <!--                备案号：冀ICP备20012958号-1-->
        <!--            </div>-->
        <!--        </footer>-->
    </div>
</template>

<script>
// @ is an alias to /src
import http from '@/utils/http'
import api from '@/api'
import axios from "axios";
import logo from '@/assets/logo.png';
import temp_qr_code from '@/assets/temp_qr_code.png';
import login_scene from '@/assets/login_scene.png';
import third_party from '@/assets/third_party.png';
import qq from '@/assets/qq.png';
import zhifubao from '@/assets/zhifubao.png';
import wechat from '@/assets/wechat.png';
import Header from "../components/Header";
import env from "@/utils/env"
import {ElNotification} from "element-plus";

export default {
    name: 'Login',
    components: {Header},
    data() {
        return {
            logo: logo,
            qq: qq,
            zhifubao: zhifubao,
            wechat: wechat,
            need_reload: false,
            is_loading: false,
            show_qr_code_login: true,
            activeName: 'first',
            login_scene: login_scene,
            third_party: third_party,
            form: {
                name: '',
                pass: '',
                captcha: ''
            },
            code: '',
            state: '',
            show_qr_code: false,
            id: '',
            ticket_url: temp_qr_code,
            timer: undefined,
            time_count: 0,
            agent: 'O000Z2I10001',
        }
    },
    mounted() {
        let query = this.$route.query;
        console.log(query)

        if (query.token !== undefined) {
            this.is_loading = true
            this.need_login(query.token)
        } else if (query.code !== undefined && query.state !== undefined) {
            this.code = query.code
            this.state = query.state
            this.open_login()
        } else {
            if (query.agent !== undefined) {
                this.agent = query.agent
                this.get_agent()
            } else {
                this.show_qr_code_login = false
                ElNotification({
                    title: '友情提醒',
                    message: '路径不正确!',
                    type: 'warning',
                })
            }
        }
    },
    methods: {
        go_to_register() {
            this.$router.replace('/register')
        },
        sign_out() {
            localStorage.clear()
            this.$router.push('/')
        },
        reload_qr_code() {
            let redirect_uri = env[process.env.NODE_ENV].REDIRECT_URL
            let url = 'https://open.weixin.qq.com/connect/qrconnect?' +
                'appid=wxd67031c204e948ba' +
                '&redirect_uri=' + redirect_uri +
                '&response_type=code' +
                '&scope=snsapi_login' +
                '&state=' + this.agent +
                '#wechat_redirect'
            window.open(url)
            return false
            this.need_reload = !this.need_reload
            this.get_login_qr_code()
        },
        set_wx_qr_code() {
            this.show_wx_qr_code = !this.show_wx_qr_code

            console.log(window.location.origin)

            const s = document.createElement('script')
            s.type = 'text/javascript'
            s.src = 'http://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
            const wxElement = document.body.appendChild(s)
            const url = `http://localhost:8080/login?time=` + (new Date()).getTime()
            // const url = window.location.origin + `https://api-zhiyuan.kboy.fun/website/v1/open-wx/access-token?time=` + (new Date()).getTime()
            wxElement.onload = () => {
                const obj = new WxLogin({
                    self_redirect: true,
                    id: "login_container",
                    appid: "wxd67031c204e948ba",
                    scope: "snsapi_login",
                    redirect_uri: encodeURIComponent(url),
                    state: Math.ceil(Math.random() * 1000),
                    style: "black",
                    //href: ""
                });
            }
        },
        get_access_token() {
            this.show_wx_qr_code = !this.show_wx_qr_code

            let that = this
            http.post(api.open_wx.access_token, {
                'code': this.code,
                'state': this.state,
            }).then(data => {
                console.log(data)
            }).catch(err => {
                let data = err.response.data.data
                let str = '';
                for (let i in data) {
                    str += data[i].toString() + "; ";
                }
                this.$notify.error({
                    title: '错误',
                    message: str,
                    offset: 50,
                });
            })
        },
        get_login_qr_code() {
            let that = this
            http.get(api.user.login_qr_code, {}).then(data => {
                console.log(data)
                console.log(data.data)
                that.ticket_url = data.data.ticket_url
                that.id = data.data.id
                that.time_count = 0
                that.timer = setInterval(function () {
                    that.get_scan_status()
                    that.time_count++
                    if (that.time_count > 15) {
                        clearInterval(that.timer)
                        that.need_reload = !that.need_reload
                    }
                }, 2000);
            }).catch(err => {
                let data = err.response.data.data
                let str = '';
                for (let i in data) {
                    str += data[i].toString() + "; ";
                }
                this.$notify.error({
                    title: '错误',
                    message: str,
                    offset: 50,
                });
            })
        },
        get_agent() {
            let that = this
            axios.post(
                api.user.get_agent, {'agent': this.agent}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                if (res.data.code === 10000) {
                    localStorage.setItem('agent_str', that.agent)
                } else {
                    that.show_qr_code_login = false
                    ElNotification({
                        title: '友情提醒',
                        message: res.data.data.msg,
                        type: 'warning',
                    })
                }
            }).catch(function (err) {
                console.log(err)
                console.log(err.response)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        get_scan_status() {
            let that = this
            axios.post(
                api.user.scan_status, {scan_id: that.id}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                if (res.data.code === 10000) {
                    clearInterval(that.timer);

                    localStorage.setItem('group_id', res.data.data.group_id)
                    localStorage.setItem('group_name', res.data.data.group_name)
                    localStorage.setItem('level_name', res.data.data.level_name)
                    localStorage.setItem('show_vip', res.data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    localStorage.setItem('name', res.data.data.name)
                    localStorage.setItem('jwt', res.data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(res.data.data.status))

                    that.$router.push('/home_page')
                } else if (res.data.code === 8888) {
                    //
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg
                    });
                }
            }).catch(function (err) {
                console.log(err)
                console.log(err.response)
                clearInterval(that.timer);
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        open_login() {
            console.log('open_login')
            let that = this
            axios.post(
                api.open_wx.access_token,
                {code: this.code, state: this.state}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                if (res.data.code === 10000) {
                    clearInterval(that.timer);

                    if (res.data.data.status != 0 && res.data.data.status != 1 && res.data.data.status != 2) {
                        that.$notify.error({
                            title: '错误',
                            message: '您目前已无法登录，请联系服务商!'
                        });
                        return false
                    }

                    localStorage.setItem('group_id', res.data.data.group_id)
                    localStorage.setItem('group_name', res.data.data.group_name)
                    localStorage.setItem('level_name', res.data.data.level_name)
                    localStorage.setItem('show_vip', res.data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    localStorage.setItem('name', res.data.data.name)
                    localStorage.setItem('jwt', res.data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(res.data.data.status))

                    that.$router.push('/home_page')
                } else if (res.data.code === 8888) {
                    //
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg
                    });
                }
            }).catch(function (err) {
                console.log(err)
                console.log(err.response)
                clearInterval(that.timer);
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        need_login(token) {
            let that = this
            axios.post(
                api.user.need_login, {token: token}
            ).then(function (res) {
                console.log(res)
                console.log(res.data.data)
                if (res.data.code === 10000) {
                    clearInterval(that.timer);

                    localStorage.setItem('group_id', res.data.data.group_id)
                    localStorage.setItem('group_name', res.data.data.group_name)
                    localStorage.setItem('level_name', res.data.data.level_name)
                    localStorage.setItem('show_vip', res.data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', res.data.data.base_info.vip_level)
                    localStorage.setItem('name', res.data.data.name)
                    localStorage.setItem('jwt', res.data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(res.data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(res.data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(res.data.data.status))
                    if (res.data.data.teacher_login) {
                        localStorage.setItem('teacher_login', res.data.data.teacher_login)
                    }

                    that.$router.push('/home_page')
                } else if (res.data.code === 8888) {
                    //
                } else {
                    clearInterval(that.timer);
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg
                    });
                }
            }).catch(function (err) {
                console.log(err)
                console.log(err.response)
                clearInterval(that.timer);
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg
                });
            });
        },
        user_info() {
            let that = this
            http.post(api.user.info, {
                'pass': this.form.pass,
                'captcha': this.form.captcha
            }).then(data => {
                console.log(data)
                if (data.code !== 10000) {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                } else {
                    localStorage.setItem('group_id', data.data.group_id)
                    localStorage.setItem('group_name', data.data.group_name)
                    localStorage.setItem('level_name', data.data.level_name)
                    localStorage.setItem('show_vip', data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', data.data.base_info.vip_level)
                    localStorage.setItem('name', data.data.name)
                    localStorage.setItem('jwt', data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(data.data.status))
                    that.$router.push('/home_page')
                }
            }).catch(err => {
                let data = err.response.data.data
                let str = '';
                for (let i in data) {
                    // str += i + ':' + data[i].toString() + '; ';
                    str += data[i].toString() + "; ";
                }
                this.$notify.error({
                    title: '错误',
                    message: str,
                    offset: 50,
                });
            })
        },
        onSubmit() {
            let that = this
            http.post(api.user.login, {
                'name': this.form.name,
                'pass': this.form.pass,
                'captcha': this.form.captcha
            }).then(data => {
                console.log(data)
                if (data.code !== 10000) {
                    this.$notify.error({
                        title: '错误',
                        message: data.data.msg,
                        offset: 50,
                    });
                } else {
                    localStorage.setItem('group_id', data.data.group_id)
                    localStorage.setItem('group_name', data.data.group_name)
                    localStorage.setItem('level_name', data.data.level_name)
                    localStorage.setItem('show_vip', data.data.base_info.show_vip)
                    localStorage.setItem('vip_level', data.data.base_info.vip_level)
                    localStorage.setItem('name', data.data.name)
                    localStorage.setItem('jwt', data.data.jwt)
                    localStorage.setItem('base_info', JSON.stringify(data.data.base_info))
                    localStorage.setItem('branch', JSON.stringify(data.data.branch))
                    localStorage.setItem('user_status', JSON.stringify(data.data.status))
                    that.$router.push('/home_page')
                }
            }).catch(err => {
                let data = err.response.data.data
                let str = '';
                for (let i in data) {
                    // str += i + ':' + data[i].toString() + '; ';
                    str += data[i].toString() + "; ";
                }
                this.$notify.error({
                    title: '错误',
                    message: str,
                    offset: 50,
                });
            })
        }
    }
}
</script>

<style scoped="scoped">
* {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.header {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    padding: 0;
}

.container {
    background-color: #FFFFFF;
    text-align: center;
    margin: 70px auto 0;
    width: 64%;
    height: 600px;
}

.left_content {
    float: left;
    width: 55%;
    margin-right: 100px;
}

.left_content_img {
    width: 100%;
}

.right_content {
    float: right;
    width: 40%;
    margin-top: 124px;
}

.header_info {
    text-align: center;
    margin: 0 auto;
    width: 64%;
    display: flex;
    display: -webkit-flex;
    height: 100px;
    line-height: 100px;
}

.div_logo {
    justify-content: flex-start;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.img_logo {
    height: 100px;
}

.name_logo {
    height: 100px;
    line-height: 100px;
    margin-left: 20px;
    font-weight: bold;
    font-size: 30px;
}

.div_nav {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.div_nav_a {
    text-decoration: none;
    cursor: pointer;
}

.div_nav a {
    margin-left: 50px;
}

</style>