<template>
    <article style="width: 100%;height: 100%;">
        <div id="gaodeditu" style="width:1600px;height:800px; "></div>
    </article>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';

export default {
    name: 'SchooMap',
    components: {},
    data() {
        return {}
    },
    mounted() {
        let div = document.getElementById('gaodeditu')
        div.style.height = document.documentElement.clientHeight + 'px'
        div.style.width = document.documentElement.clientWidth + 'px'
        let query = this.$route.query;
        if (query.school_name !== undefined) {
            this.initMap(query.school_name)
        }
    },
    created() {
    },
    methods: {
        initMap(school_name = '') {
            let that = this
            AMapLoader.load({
                key: "8471b39e4309c73de9e81395039d1b01",
                version: "2.0",
                plugins: ['AMap.PlaceSearch'],
            }).then((AMap) => {
                const {Map, PlaceSearch} = AMap

                const map = new Map('gaodeditu', {
                    center: [120.980795, 31.385476],
                    zoom: 15,
                    resizeEnable: true
                });

                const placeSearch = new PlaceSearch({
                    pageSize: 10, // 单页显示结果条数
                    pageIndex: 1, // 页码
                    city: "010", // 兴趣点城市
                    citylimit: false,  //是否强制限制在设置的城市内搜索
                    map: map, // 展现结果的地图实例
                    autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                });
                //关键字查询
                placeSearch.search(school_name, (status, result) => {
                    console.log(status);
                    console.log(result);
                });
            }).catch(e => {
                console.log(e);
            })
        },
    }
}
</script>