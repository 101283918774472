<template>
    <Header chose_index="8"></Header>
    <article class="container" id="assess_info">
        <el-tabs v-model="activeName" class="demo-tabs"
                 @tab-click="handleClick"
        >
            <el-tab-pane label="霍兰德性格测试" name="second">
                <div v-if="show_results_status">
                    <div style="margin: 0 auto;" id="print_content">
                        <div style="margin-bottom: 20px;position: relative;">
                            <div class="title_info">霍兰德职业兴趣测评结果</div>
                            <div class="introduce">
                                <div style="padding-left: 30px;margin-bottom: 30px;">
                                    您是: <span style="font-size: 20px;color: #ba0806;">{{ solution }}</span>型
                                    ({{ question_info[sort_list[5]]['ming'] }}{{ question_info[sort_list[5]]['num'] }} +
                                    {{ question_info[sort_list[4]]['ming'] }}{{ question_info[sort_list[4]]['num'] }} +
                                    {{ question_info[sort_list[3]]['ming'] }}{{ question_info[sort_list[3]]['num'] }})
                                </div>
                                <div id="e_chart_id" style="width:400px;height:500px;margin: 0 auto;"></div>
                                <div style="line-height: 180%;margin-bottom: 50px;text-indent: 30px;color:#999999">
                                    说明:霍兰德职业兴趣的六大类型并非有着清晰的边界和顺序，相邻关系的类型共同点较多，但相隔关系的类型共同点较少，相对关系的类型共同点最少。
                                </div>
                                <div class="ming-2" style="width: 400px;cursor: pointer;margin-top: 50px;">霍兰德职业兴趣代码解析
                                </div>
                                <div>
                                    <el-table :data="hld_major_desc" stripe style="width: 100%;margin-top: 10px;">
                                        <el-table-column prop="level2_name" label="类型" width="100">
                                            <template #default="scope">
                                                <span>{{ scope.row.code }}({{ scope.row.code_desc }})</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="personality_traits" label="人格特征"/>
                                        <el-table-column prop="occupational_traits" label="职业特征"/>
                                    </el-table>
                                </div>
                                <div class="ming-2" style="width: 400px;cursor: pointer;margin-top: 50px;">匹配专业</div>
                                <div>
                                    <el-table :data="hld_major_list" stripe style="width: 100%;margin-top: 10px;">
                                        <el-table-column prop="level1_name" label="一级分类"/>
                                        <el-table-column prop="level2_name" label="二级分类">
                                            <template #default="scope">
                                            <span
                                                @click="show_majors_job(scope.row.level2_code, scope.row.level2_name)">{{
                                                    scope.row.level2_name
                                                }}</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="collection"
                                            label="操作"
                                            width="100"
                                            filter-placement="bottom-end"
                                        >
                                            <template #default="scope">
                                                <el-button v-if="scope.row.collection == 1"
                                                           size="small"
                                                           style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;"
                                                           type="danger"
                                                           @click="hld_collection(scope.row.code, 1)" plain>
                                                    已选择
                                                </el-button>
                                                <el-button v-else
                                                           style="padding: 0 10px;border: 1px #ba0806 solid; color: #ba0806;"
                                                           type="text"
                                                           size="small"
                                                           @click="hld_collection(scope.row.code, 0)" plain>
                                                    选择
                                                </el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                                <div class="ming-2" style="width: 400px;cursor: pointer;margin-top: 50px;">选科分析</div>
                                <div id="major_hld_analysis" style="width: 1000px;height: 550px;">

                                </div>
                                <div
                                    style="margin: 20px auto;line-height: 180%;font-size: 14px;text-indent: 30px;color: #666666;">
                                    根据您的测评结果，您适合就读的专业依次为：{{
                                        major_l2_list
                                    }}。下面是根据您适合就读的专业所做的选科分析，分析依据为教育部各专业教指委编写的《本科专业类教学质量国家标准》中对于各专业学习要求的通识描述，同时考虑考生在该专业方向上的考研需求及长远发展。<span
                                    style="color:#B7281B;">该分析具有普适性，并不针对某一院校的具体选科要求。</span>
                                </div>
                                <el-table :data="major_hld" stripe style="width: 100%;margin-top: 10px;">
                                    <el-table-column prop="l2_name" label="专业名称">
                                        <template #default="scope">
                                            <div style="color: #ba0806;"><b>专业类</b>: {{ scope.row.l2_name }}</div>
                                            <div><b>专业</b>: {{ scope.row.major_name }}</div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="suggest" label="总体建议">
                                        <template #default="scope">
                                            <div v-html="scope.row.suggest"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="first_choice_desc" label="首选科目分析">
                                        <template #default="scope">
                                            <div v-html="scope.row.first_choice_desc"></div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="another_desc" label="再选科目分析">
                                        <template #default="scope">
                                            <div v-html="scope.row.another_desc"></div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="ming-2" style="width: 400px;cursor: pointer;margin-top: 50px;">就业方向</div>
                                <div>
                                    <el-table :data="hld_major_direction" stripe
                                              style="width: 100%;margin-top: 10px;" empty-text="请选择专业">
                                        <el-table-column prop="majros" label="专业名称" width="200">
                                            <template #default="scope">
                                                <div v-html="scope.row.majors"></div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="desc" label="就业方向">
                                            <template #default="scope">
                                                <div v-html="scope.row.desc"></div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>

                            </div>
                            <div>

                            </div>
                        </div>
                    </div>
                    <div style="margin: 0 auto;width: 100%;text-align: center;">
                        <el-button
                            style="margin: 0 auto;background-color: #ba0806;border: 1px #ba0806 solid;color: white;" @click="button_click"
                        >打印
                        </el-button>
                    </div>
                </div>
                <div v-else style="margin: 0 auto;text-align: center;">
                    <div style="margin-bottom: 20px;position: relative;">
                        <div class="title_info">霍兰德职业兴趣测评介绍</div>
                        <div class="introduce">
                            <div style="text-align: left;text-indent:30px; margin: 50px auto 10px auto;">
                                每年高考，面对近2000所高校，1000多个专业，众多学生和家长觉得手足无措，如何从这些选择中找到合适的专业？
                            </div>
                            <div style="text-align: left;text-indent:30px; line-height:180%;margin-bottom: 10px;">
                                “兴趣+分数(能力)+未来发展”是选专业的三大核心要素，盲目跟风热点专业等很容易导致学生对所限专业不感兴趣，造成学业和就业的两难局面。从职业兴趣倒推所选专业，能帮助学生找到心仪的、可以最大程度发挥学生主管能动性的合适专业。
                            </div>
                            <div style="text-align: left;text-indent:30px; margin-bottom: 10px;">
                                国际最为流行的职业兴趣测评工具，美国约翰·霍普金斯大学心学教授、著名职业指导专家Holland提出。
                            </div>
                            <div style="height: 50px;"></div>
                            <div>
                                <div class="cell">
                                    <div class="tu"><b>权威</b></div>
                                    <div>
                                        <div class="c-title"><b>国际经典心里类型理论基础</b></div>
                                        <div class="c-jie">经过上百次大规模实验研究，由知名职业生涯鼻祖提出，广泛应用于职业规划领域</div>
                                    </div>
                                </div>
                                <div class="cell">
                                    <div class="tu"><b>详尽</b></div>
                                    <div>
                                        <div class="c-title"><b>详细解读性格类型、职业、专业</b></div>
                                        <div class="c-jie">精准定位你的霍兰德职业代码，全面分析各类型的职业特征和典型职业，匹配合适的专业</div>
                                    </div>
                                </div>
                                <div class="cell">
                                    <div class="tu"><b>实用</b></div>
                                    <div>
                                        <div class="c-title"><b>为职业、专业选择提供客观导向</b></div>
                                        <div class="c-jie">借助测评工具帮助你发现兴趣所在，运用AI和大数据计数，智能推荐职业和专业</div>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 50px;"></div>
                            <div>
                                <div class="ming-2">测评价值</div>
                                <div class="jia-nr">
                                    <li class="li">1.精准定位你的性格类型</li>
                                    <li class="li">2.客观描述你的性格特征</li>
                                    <li class="li">3.科学分析你的性格类型工作的优劣群</li>
                                    <li class="li">4.智能匹配适合你的职业和专业</li>
                                    <li class="li" style="padding-bottom:3px">5.预测未来你适合的工作环境</li>
                                </div>
                            </div>
                            <div style="height: 50px;"></div>
                            <div>
                                <div class="ming-2">测评流程</div>
                                <div class="jia-nr2">
                                    <div class="one">
                                        <div class="one-tu"><img :src="img09" style="width: 60px;height: 60px;"></div>
                                        <b class="one-title">开始测评</b>
                                        <div class="one-word">调整心态<br/>轻松回答</div>
                                    </div>
                                    <div class="jiantou"><img :src="img13" style="width: 40px;height: 40px;"></div>
                                    <div class="one">
                                        <div class="one-tu2"><img :src="img10" style="width: 60px;height: 60px;"></div>
                                        <b class="one-title">提交评测</b>
                                        <div class="one-word">一键提交<br/>报告生成</div>
                                    </div>
                                    <div class="jiantou"><img :src="img13" style="width: 40px;height: 40px;"></div>
                                    <div class="one">
                                        <div class="one-tu3"><img :src="img11" style="width: 60px;height: 60px;"></div>
                                        <b class="one-title">查看报告</b>
                                        <div class="one-word">完整报告<br/>全面分析</div>
                                    </div>
                                    <div class="jiantou"><img :src="img13" style="width: 40px;height: 40px;"></div>
                                    <div class="one">
                                        <div class="one-tu4"><img :src="img12" style="width: 60px;height: 60px;"></div>
                                        <b class="one-title">应用结果</b>
                                        <div class="one-word">智能匹配<br/>发展预测</div>
                                    </div>
                                </div>
                            </div>
                            <div style="height: 50px;"></div>
                            <div style="width: 600px;margin: 0 auto;display: flex">
                                <div class="go" @click="begin_question">开始测评</div>
                                <div class="go" @click="show_results">查看结果</div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="分数结构分析结果" name="first">
                <div>
                    <el-button type="danger" v-if="chose_id===50" @click="show_major_analysis(50)"
                               style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;" plain>知识符合度很高
                    </el-button>
                    <el-button type="danger" v-else @click="show_major_analysis(50)"
                               style="background-color:white;border: 1px #ba0806 solid; color: #ba0806;" plain>知识符合度很高
                    </el-button>
                    <el-button type="danger" v-if="chose_id===40" @click="show_major_analysis(40)"
                               style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;" plain>知识符合度高
                    </el-button>
                    <el-button type="danger" v-else @click="show_major_analysis(40)"
                               style="background-color:white; border: 1px #ba0806 solid; color: #ba0806;" plain>知识符合度高
                    </el-button>
                    <el-button type="danger" v-if="chose_id===30" @click="show_major_analysis(30)"
                               style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;" plain>基本符合
                    </el-button>
                    <el-button type="danger" v-else @click="show_major_analysis(30)"
                               style="background-color:white; border: 1px #ba0806 solid; color: #ba0806;" plain>基本符合
                    </el-button>
                    <el-button type="danger" v-if="chose_id===20" @click="show_major_analysis(20)"
                               style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;" plain>知识符合度低
                    </el-button>
                    <el-button type="danger" v-else @click="show_major_analysis(20)"
                               style="background-color:white; border: 1px #ba0806 solid; color: #ba0806;" plain>知识符合度低
                    </el-button>
                    <el-button type="danger" v-if="chose_id===10" @click="show_major_analysis(10)"
                               style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;" plain>知识符合度很低
                    </el-button>
                    <el-button type="danger" v-else @click="show_major_analysis(10)"
                               style="background-color:white; border: 1px #ba0806 solid; color: #ba0806;" plain>知识符合度很低
                    </el-button>
                </div>
                <el-table :data="tableData" stripe style="width: 100%;margin-top: 10px;">
                    <el-table-column prop="level1" label="一级分类"/>
                    <el-table-column prop="level2" label="二级分类"/>
                    <el-table-column prop="name" label="专业名称">
                        <template #default="scope">
                            <span @click="show_majors_job(scope.row.code, scope.row.name)">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="collection"
                        label="操作"
                        width="100"
                        filter-placement="bottom-end"
                    >
                        <template #default="scope">
                            <el-button v-if="scope.row.collection == 1"
                                       size="small"
                                       style="background-color: #ba0806;border: 1px #ba0806 solid; color: white;"
                                       type="danger"
                                       @click="collection(scope.row.code, 1)" plain>
                                已选择
                            </el-button>
                            <el-button v-else
                                       style="padding: 0 10px;border: 1px #ba0806 solid; color: #ba0806;"
                                       type="text"
                                       size="small"
                                       @click="collection(scope.row.code, 0)" plain>
                                选择
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <!--            <el-tab-pane label="自选专业选科分析" name="third">-->
            <!--                <div class="ming-2" style="width: 400px;cursor: pointer;margin-top: 50px;">选科分析</div>-->
            <!--                <div style="margin: 20px auto;line-height: 180%;font-size: 14px;text-indent: 30px;color: #666666;">下面是根据您选择的专业所做的选科分析，分析依据为教育部各专业教指委编写的《本科专业类教学质量国家标准》中对于各专业学习要求的通识描述，同时考虑考生在该专业方向上的考研需求及长远发展。<span style="color:#B7281B;">该分析具有普适性，并不针对某一院校的具体选科要求。</span>-->
            <!--                </div>-->
            <!--                <el-table :data="optional_major_analysis_list" stripe style="width: 100%;margin-top: 10px;">-->
            <!--                    <el-table-column prop="l2_name" label="专业名称">-->
            <!--                        <template #default="scope">-->
            <!--                            <div style="color: #ba0806;"><b>专业类</b>: {{scope.row.l2_name}}</div>-->
            <!--                            <div><b>专业</b>: {{scope.row.major_name}}</div>-->
            <!--                        </template>-->
            <!--                    </el-table-column>-->
            <!--                    <el-table-column prop="suggest" label="总体建议" />-->
            <!--                    <el-table-column prop="first_choice_desc" label="首选科目分析"/>-->
            <!--                    <el-table-column prop="another_desc" label="再选科目分析"/>-->
            <!--                </el-table>-->
            <!--            </el-tab-pane>-->
        </el-tabs>
    </article>
    <footer style="background-color: #F8F8F8;width:100%;height:167px;margin-top: 75px;">
        <div style="color: #999999;text-align: center;padding-top: 60px;">
            Copyright @ 2011-2022 茗硕择嘉（河北）教育科技有限公司 All Rights Reserved. 版权所有<br><br>
            备案号：冀ICP备17013465号-1
        </div>
    </footer>
    <el-dialog
        v-model="show_majors_job_info"
        title="就业方向"
        width="60%"
        :before-close="majors_job_info_close">
        <div style="overflow-y: scroll;max-height: 600px;">
            <div v-if="majors_job_info === null || majors_job_info.length < 1">暂无数据</div>
            <div v-else-if="majors_job_list.length < 1">加载中...</div>
            <div v-else>
                <div v-for="item in majors_job_info">
                    <div style="font-size: 18px;font-weight: bold;color:#f56c6c;">
                        {{ item.name }}
                        <span style="margin-left: 20px;font-size:14px;cursor: pointer;color:#333333;"
                              @click="show_major_detail(item.name, item.code)">点击查看详情>></span>
                    </div>
                    <div style="margin-bottom: 10px;" v-if="majors_job_list[item.code] !== undefined">
                        <span style="margin-right: 10px;color:#666666;"
                              v-html="majors_job_list[item.code]['job_orientation']"></span>
                    </div>
                </div>
            </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button size="small" type="primary" @click="majors_job_info_close">关闭</el-button>
          </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="begin_question_status"
        title="霍兰德性格测试"
        width="800px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose"
    >
        <div style="height:300px;">
            <div style="margin: 0 auto 20px auto;">
                <div style="margin: 0 auto;width: 90%;">
                    <el-progress :text-inside="true" :stroke-width="26" :percentage="(question_index + 1)/1.2"
                                 :color="customColors">
                        <span v-if="question_index < 120">{{ question_index + 1 }}</span><span
                        v-else>{{ question_index }}</span>&nbsp;
                    </el-progress>
                </div>
            </div>
            <div v-if="show_answer_status" style="margin: 30px auto 0 auto;">
                <div style="font-size: 18px;margin-bottom: 10px;">恭喜您测试完成!</div>
                <div v-if="answer_info.length > 1">
                    <div>
                        您是: {{ solution }}型 ({{
                            question_info[sort_list[5]]['ming']
                        }}{{ question_info[sort_list[5]]['num'] }} +
                        {{ question_info[sort_list[4]]['ming'] }}{{ question_info[sort_list[4]]['num'] }} +
                        {{ question_info[sort_list[3]]['ming'] }}{{ question_info[sort_list[3]]['num'] }})
                    </div>
                    <div>类型解析: {{ answer_info }}</div>
                </div>
                <div v-else>
                    请点击 "查看结果" 查看详情
                </div>
            </div>
            <div v-else style="margin: 30px auto 0 auto;">
                <div style="margin: 0 auto 20px auto;width: 80%;font-size: 18px;">
                    {{ questions[question_index] }}
                </div>
                <div style="margin: 0 auto 20px auto;width: 80%;">
                    <el-button @click="confirm_question" type="success"
                               style="width:80px;background-color: #ba0806;border: 1px #ba0806 solid" size="small">
                        是
                    </el-button>
                    <el-button @click="cancel_question" type="warning" style="width:80px;" size="small" plain>
                        否
                    </el-button>
                </div>
            </div>
        </div>
        <template #footer>
                <span class="dialog-footer">
                    <el-button v-if="show_answer_status" @click="show_answer">查看结果</el-button>
                    <el-button v-else @click="back_question"
                               style="width:80px;background-color: #ba0806;border: 1px #ba0806 solid;color:white">上一题</el-button>
                </span>
        </template>
    </el-dialog>
    <el-dialog
        v-model="show_help"
        title="操作提醒"
        top="300"
        width="1100px"
        :before-close="helpHandleClose"
    >
        <img :src="print" width="1000"/>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="show_help = false">取消</el-button>
        <el-button v-print="printObj" type="primary" @click="show_help = false">开始打印报告</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
import api from '@/api'
import axios from 'axios'
import Header from '@/components/Header.vue'
import logo from '@/assets/logo.png';
import zhi from '@/assets/zhi.png';
import yuan from '@/assets/yuan.png';
import img09 from '@/assets/ce_img_9.png'
import img10 from '@/assets/ce_img_10.png'
import img11 from '@/assets/ce_img_11.png'
import img12 from '@/assets/ce_img_12.png'
import print from '@/assets/report/print.png'
import img13 from '@/assets/ce_img_13.png'
import {ElMessageBox} from "element-plus";
import * as echarts from "echarts";

export default {
    name: 'Assess_Info',
    components: {Header},
    data() {
        return {
            customColors: [
                {color: '#ba0806', percentage: 20},
                {color: '#ba0806', percentage: 40},
                {color: '#ba0806', percentage: 60},
                {color: '#ba0806', percentage: 80},
                {color: '#ba0806', percentage: 100},
            ],
            printObj: {
                id: "print_content",
                popTitle: ".",
            },
            myChart: null,
            show_help: false,
            show_background: true,
            img09: img09,
            img10: img10,
            img11: img11,
            print: print,
            img12: img12,
            img13: img13,
            logo: logo,
            zhi: zhi,
            yuan: yuan,
            activeName: 'second',
            allTableData: {},
            tableData: [],
            hld_major_direction: [],
            hld_major_list: [],
            major_l2_list: [],
            hld_major_desc: [],
            major_hld: [],
            optional_major_analysis_list: [],
            chose_id: 30,
            show_majors_job_info: false,
            show_results_status: false,
            majors_job_info: [],
            majors_job_list: [],
            hls_result: [0, 0, 0, 0, 0, 0],
            questions: [
                "1.装配修理电器或玩具",
                "2.修理自行车",
                "3.用木头做东西",
                "4.开汽车或摩托车",
                "5.用机器做东西",
                "6.参加木工技术学习班",
                "7.参加制图描图学习",
                "8.驾驶卡车或拖拉机",
                "9.参加机械和电气学习",
                "10.装配修理电器",
                "11.素描／制图或绘画",
                "12.参加话剧戏曲",
                "13.设计家具布置室内",
                "14.练习乐器／参加乐队",
                "15.欣赏音乐或戏剧",
                "16.看小说／读剧本",
                "17.从事摄影创作",
                "18.写诗或吟诗",
                "19.进艺术(美 / 音)培训班",
                "20.练习书法",
                "21.读科技图书和杂志",
                "22.在试验室工作",
                "23.改良品种, 培育新水果",
                "24.分析土和金属等的成分",
                "25.研究自己选择的问题",
                "26.解算式或数学游戏",
                "27.学物理课",
                "28.学化学课",
                "29.学几何课",
                "30.学生物课",
                "31.学校或单位的正式活动",
                "32.参加社会团体或俱乐部",
                "33.帮助别人解决困难",
                "34.照顾儿童",
                "35.晚会、联欢会、茶话会",
                "36.和大家一起出去郊游",
                "37.获得心理方面的知识",
                "38.参加讲座会或辩论会",
                "39.观看或参加体育比赛",
                "40.结交新朋友",
                "41.说服鼓动他人",
                "42.卖东西",
                "43.谈论政治",
                "44.制定计划、参加会议",
                "45.影响别人的行为",
                "46.在社会团体中任职",
                "47.检查与评价别人的工作",
                "48.结识名流",
                "49.指导项目小组",
                "50.参与政治活动",
                "51.整理好桌面和房间",
                "52.抄写文件和信件",
                "53.为领导写报告或公函",
                "54.查收个人收支情况",
                "55.参加打字培训班",
                "56.参加算盘，文秘等培训",
                "57.参加商业会计培训班",
                "58.参加情报处理培训班",
                "59.整理信件、报告、记录等",
                "60.写商业贸易信",
                "61.能使用电锯、电钻和锉刀等木工工具",
                "62.知道万用表的使用方法",
                "63.能够修理自行车或其他机械",
                "64.能够使用电钻床、磨床或缝纫机",
                "65.能给家具和木制品刷漆",
                "66.能看建筑等设计图",
                "67.能够修理简单的电气用品",
                "68.能够修理家具",
                "69.能修收录机",
                "70.能简单地修理水管",
                "71.能演奏乐器",
                "72.能参加二部或四部合唱",
                "73.独唱或独奏",
                "74.扮演剧中角色",
                "75.能创作简单的乐曲",
                "76.会跳舞",
                "77.能绘画、素描或书法",
                "78.能雕该刻、剪纸或泥塑",
                "79.能设计海报、服装或家具",
                "80.写得一手好文章",
                "81.懂得真空管或晶体管的作用",
                "82.能够举例三种含蛋白质多的食品",
                "83.理解铀的裂变",
                "84.能用计算尺、计算器、对数表",
                "85.会使用显微镜",
                "86.能找到三个星座",
                "87.能独立进行调查研究",
                "88.能解释简单的化学式",
                "89.理解人造卫星为什么不落地",
                "90.经常参加学术的会议",
                "91.有向各种人说明解释的能力",
                "92.常参加社会福利活动",
                "93.能和大家一起友好相处地工作",
                "94.善于与年长者相处",
                "95.会邀请人招待人",
                "96.能简单易懂地教育儿童",
                "97.能安排会议等活动顺序",
                "98.善于体察人心和帮助他人",
                "99.帮助护理病人或伤员",
                "100.安排社团组织的各种事务",
                "101.担任过学生干部并且干得不错",
                "102.工作上能指导和监督他人",
                "103.做事充满活力和热情",
                "104.有效地用自身的做法调动他人",
                "105.销售能力强",
                "106.曾作为俱乐部或社团的负责人",
                "107.向领导提出建议或反映意见",
                "108.有开创事业的能力",
                "109.知道怎样做能成为一个优秀的领导者",
                "110.健谈善辩",
                "111.会熟练地打印中文",
                "112.会用外文打字机或复印机",
                "113.能快速记笔记和抄写文章",
                "114.善于整理保管文件和资料",
                "115.善于从事事务性的工作",
                "116.会用算盘",
                "117.能在短时间内分类和处理大量文件",
                "118.能使用计算机",
                "119.能搜集数据",
                "120.善于为自己或集体作财务预算表",
            ],
            question_index: 0,
            begin_question_status: false,
            show_answer_status: false,
            outcome: {'r': 0, 'a': 0, 'i': 0, 's': 0, 'e': 0, 'c': 0,},
            sort_list: ['r', 'a', 'i', 's', 'e', 'c'],
            question_info: {
                'r': {name: 'R', num: 0, ming: "现实", jie: '创造性思想和情感的表达'},
                'a': {name: 'A', num: 0, ming: "艺术", jie: '创造性思想和情感的表达'},
                'i': {name: 'I', num: 0, ming: "研究", jie: '思考和解决问题，以得出结论或概念'},
                's': {name: 'S', num: 0, ming: "社会", jie: '理解他人需要并提供帮助'},
                'e': {name: 'E', num: 0, ming: "企业", jie: '影响和控制他人'},
                'c': {name: 'C', num: 0, ming: "传统", jie: '与信息、规则打交道'}
            },
            answer_info: '',
            answer: {
                RIA: '适合的典型专业有：冶金工程、纺织工程、金属材料工程、船舶与海洋工程、海洋技术学。',
                RIS: '适合的典型专业有：冶金工程、金属材料工程、热能与动力工程、电气工程及其自动化、计算机科学与技术、交通运输、环境工程、油气储运工程、海洋技术、医学检验、针灸推拿学、法医学、林学、动物科学、港口航道与海岸工程、化学工程与工艺、电子信息科学与技术',
                RIE: '适合的典型专业有：冶金工程、金属材料工程、电气工程及其自动化、测控技术与仪器、油气储运工程、船舶与海洋工程、轻化工程、海洋技术、采矿工程、动物科学',
                RIC: '适合的典型专业有：信息管理与信息系统、冶金工程、金属材料工程、过程装备与控制工程、热能与动力工程、电气工程及其自动化、通信工程、计算机科学与技术、交通运输、教育技术学、植物保护学、动物医学、机械设计制造及其自动化、测控技术与仪器、土木工程、环境工程、油气储运工程、船舶与海洋工程、轻化工程、化学类、地理信息系统、海洋技术、医学检验、法医学、港口航道与海岸工程',
                RAI: '适合的典型专业有：油气储运工程、动物科学、体育学类、广播电视编导',
                RSI: '适合的典型专业有：环境工程、油气储运工程、针灸推拿学、动物科学、港口航道与海岸工程、城市规划',
                REC: '适合的典型专业有：油气储运工程、物业管理、物流、工程管理、交通管理等。',
                REI: '适合的典型专业有：油气储运工程、动物科学、农林牧渔业经营、航运管理等。',
                RES: '适合的典型专业有：油气储运工程、动物科学、物流管理、旅游管理、海事、农业机械、建筑施工、体育',
                RCI: '适合的典型专业有：临床医学、建筑学、麻醉学、医学影像学、农学、教育技术学、勘查技术与工程、建筑环境与设备工程、动物医学、土木工程、环境工程、油气储运工程、航海技术、地理信息系统、港口航道与海岸工程',
                RCS: '适合的典型专业有：临床医学、建筑学、勘查技术与工程、建筑环境与设备工程、环境工程、油气储运工程、航海技术、港口航道与海岸工程、给排水工程',
                RCE: '适合的典型专业有：勘查技术与工程、油气储运工程、航海技术、工程管理、测绘工程、建筑工程、机械工程等',
                IAS: '适合的典型专业有：汉语言文学、工业设计、建筑设计、城市规划、环境工程',
                IAR: '适合的典型专业有：纺织工程、园林工程、建筑设计、考古学、地质工程',
                ISE: '适合的典型专业有：资源环境与城乡规划管理、经济学、国际经济与贸易、社会学、地理学、环境科学',
                ISC: '适合的典型专业有：汉语言文学、药学、教育学、哲学、国际经济与贸易、社会学、政治学与行政学、地理科学、环境科学等',
                ISR: '适合的典型专业有：生物学、药学、经济学、交通工程、地质学、中医学、应用物理学等',
                ISA: '适合的典型专业有：汉语言文学、环境科学、护理学、医疗器械工程、工业心理学、管理心理学等',
                IES: '适合的典型专业有：水利水电工程、经济学、资源环境与城乡规划管理、水利水电工程等。',
                IEC: '适合的典型专业有：水利水电工程、计算机科学、软件工程、财政学、财务管理、应用数学',
                ICR: '适合的典型专业有：药学、制药工程、交通工程、地质学、地球物理学、大气科学、中医学、基础医学、自动化、水文与水资源工程、统计学等',
                IRA: '适合的典型专业有：纺织工程、冶金工程、金属材料工程、生物医学工程、测绘工程、法医学',
                IRS: '适合的典型专业有：动物科学、药学、牙科学、经济学、管理科学、冶金工程、金属材料工程、热能与动力工程、电气工程及其自动化、计算机科学与技术、交通工程、地质学、材料化学、中医学、农业资源与环境、交通运输、应用物理学等',
                IRE: '适合的典型专业有：经济学、材料成型及控制工程、电气工程及其自动化、物理学、化学、生物科学、生物化学、材料化学、微电子学、轻化工程、采矿工程、民用工程、地理学、地质学、数学、计算机科学、统计学、人种学、考古学、环境科学、农业工程、计算机工程、电子工程、机械工程、建筑学等',
                IRC: '适合的典型专业有：管理科学、冶金工程、金属材料工程、无机非金属材料工程、高分子材料与工程、材料成型及控制工程、过程装备与控制工程、热能与动力工程、电气工程及其自动化、通信工程、计算机科学与技术、生物医学工程、测绘工程、制药工程、交通工程、信息与计算科学、物理学、生物科学类、地质学、地球物理学、大气科学类、理论与应用力学、材料化学、中医学、交通运输',
                CRI: '适合的典型专业有：印刷工程、财务管理、临床医学、档案学等',
                CRS: '适合的典型专业有：财务管理、临床医学、档案学、图书馆学、会计学等',
                CRE: '适合的典型专业有：印刷工程、商务信息管理等',
                CIS: '适合的典型专业有：金融学、档案学、安全工程等',
                CIE: '适合的典型专业有：会计学、印刷工程等',
                CIR: '适合的典型专业有：档案学、安全工程、印刷工程等',
                CSE: '适合的典型专业有：行政管理、会计学、图书馆学、秘书等',
                CSR: '适合的典型专业有：档案学、图书馆学、侦查学、会计等',
                CSA: '适合的典型专业有：博物馆学、艺术编辑、艺术史、会展、教育技术学等',
                CER: '适合的典型专业有：秘书、会计、图书馆学、档案学、侦查学等',
                CEI: '适合的典型专业有：印刷工程、会计、计量经济学等',
                CES: '适合的典型专业有：行政管理、法律、人力资资源管理、公共事业管理、会计等',
                ECI: '适合的典型专业有：法律、金融、会计、房产、财务管理、商务管理等',
                ECS: '适合的典型专业有：会计、旅游管理、城市管理、商务管理、金融管理等',
                ERI: '适合的典型专业有：管理信息工程、科技英语、工程管理、技术教育、教育技术、销售管理、电子商务、自动化控制等',
                ERA: '适合的典型专业有：乡镇企业管理、销售管理、现场施工、生产管理等',
                ERS: '适合的典型专业有：艺术史、民法学、物流管理、物业管理、市场营销等',
                ERC: '适合的典型专业有：电子商务、钳工、生产管理、汽车维修、工程管理、工业管理等',
                EIR: '适合的典型专业有：工业工程、能源经营与金融、工程管理、管业管理、公共事业管理、工商管理等',
                EIC: '适合的典型专业有：环境工程、工业工程、工商管理、安全管理等',
                EIS: '适合的典型专业有：资源环境与城乡规划管理、当代史研究、跨学科经济学、军事管理、交通管理、商务管理、教育管理、物业管理等',
                EAS: '适合的典型专业有：广告学、关公关系学、营销学、信贷分析师、农业经济学、工商管理、考古学、播音与主持、传播学、新闻学、国际关系学',
                EAR: '适合的典型专业有：管理学、工商管理、海关、公安、安全检查',
                ESC: '适合的典型专业有：公共关系、商务沟通、公共管理、商务管理、出版发行、新闻、传播学、商务管理、金融学、管理学、公共卫生学、工程管理、旅游管理等',
                ESR: '适合的典型专业有：金融学、商业教育、金融学、市场营销、商务管理、出版发行、旅游管理、国际商务、金融管理、公共管理等',
                ESI: '适合的典型专业有：资源环境与城乡规划管理、国际政治、销售管理、侦查学、社区医学/公共卫生学、公共管理、国际研究、政治学、信息管理、法学、金融学、社会工作等',
                ESA: '适合的典型专业有：法律、公共管理、社区服务、社会工作、新闻、旅游管理、轻纺管理',
                ASE: '适合的典型专业有：广播电视新闻学、外国文学、心理学、戏剧、中国文学、音乐表演、动画、广告设计、会展等等',
                ASI: '适合的典型专业有：音乐、美术、戏剧与影视、舞蹈、广告设计、编导、音乐教育、美术教师、哲学、外语、中文等',
                AER: '适合的典型专业有：绘画、动漫制作、戏剧与影视艺术、服装设计、会展设计、广告设计等',
                //AEI: '适合的典型专业有：中文、喜剧、会展设计、绘画、音乐',
                AES: '适合的典型专业有：广告设计、服装设计、音乐/戏剧表演、装潢设计、会展/博物馆设计、影视制作/媒体设计、绘画、语言艺术、戏剧、艺术教育、护理',
                AIS: '适合的典型专业有：戏剧影视文学、文学、、语言艺术、工业设计、作曲、演奏、媒体制作、人类学、民俗学、地产评估、博物馆学',
                AIE: '适合的典型专业有：工业设计、记者/编辑、博物馆学、语言艺术、媒体制作、哲学、民俗学、人类学、医疗影像学',
                AIR: '适合的典型专业有：纺织工程、包装工程、媒体制作、景观设计、建筑设计、汽车设计',
                SEC: '适合的典型专业有：市场营销、行政管理、国际政治、治安学、人力资源管理、旅游管理、公共事业管理、劳动与社会保障、土地资源管理、法学、新闻学等',
                SER: '适合的典型专业有：公共事业管理、体育、交通管理、公安学、护理学、公共关系',
                SEA: '适合的典型专业有：护理学、社会工作、公告学、广播电视编导、教育学、学前教育学、人力资源管理、家政学',
                SCE: '适合的典型专业有：法学、地产评估、行政管理、人力资源管理、工商管理、对外汉语、治安学、社会工作、旅游管理、劳动与社会保障、新闻学。',
                SRI: '适合的典型专业有：工程管理学、城市规划、急救技术、园艺、放射学、理疗学、体育学、外科医学、职业病学、医疗工程技术学',
                SRE: '适合的典型专业有：体育学、中医学、康复医学、兽医学、护理学、广播电视编导、舞蹈、杂技、外科医学、牙科医学、治安学。',
                SRC: '适合的典型专业有：护理学、康复医学、特殊教育、兽医学、中医学、学前教育学、动物学、体育学。',
                SIA: '适合的典型专业有：工商管理、人力资源管理、应用心理学、社会学、教育学、政策学、生物学、生命科学',
                SIE: '适合的典型专业有：食品学、关公关系学、护理学、历史学、哲学、社会学、政策学、劳动与劳动保障、新闻学、物流管理、城乡规划学、国际经济与贸易、人力资源管理、海关稽查、税务学、公共卫生学。',
                SIC: '适合的典型专业有：英语及其他外语、中文、历史、哲学、劳动与劳动保障、新闻学、物流管理、心理学、教育学、国际经济与贸易、社会学、社会工作、人力资源管理',
                SIR: '适合的典型专业有：特殊教育、中医学、兽医学、动物学、护理学、体育教练、生命科学、语言病理学、健康教育',
                SAE: '适合的典型专业有：幼儿教育、各科师范教育专业、广播电视新闻学、外语教育、宗教学、生物教育、地球科学、中文教育、健康教育、历史教育',
                ACE: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑创意大类的专业与职业，尤其是精细的公共艺术，如广告设计、宣传画、影视摄影、漫画、动漫设计、发型设计、化妆、服装设计、播音主持等。',
                ACI: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑创意大类的专业与职业，尤其是精细而又高知识含量的艺术，如广告设计、宣传画、影视摄影、动漫设计、会展设计、建筑设计、书刊插图设计等。',
                ACR: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑创意大类的专业与职业，尤其是精细而需要动手操作的公共艺术，如雕塑、舞蹈、漫画、服装设计、建筑设计、工艺品设计、模具师等。',
                ACS: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑创意大类的专业与职业，尤其是精细而需要与人互动的公共艺术，如话剧、歌唱、相声等表演艺术，美容、美发、服装设计等服务性艺术，艺术教师、语言教师、书法家、笔迹鉴定师等。',
                AEC: '适合的专业有工艺美术、广告学、服装设计与工程、新闻学等。',
                AEI: '适合的专业有语言学、心理学、音乐学、戏剧学、广告学、新闻学、会展设计、服装设计与工程、绘画、曲艺、包装设计等。',
                AIC: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑创意大类的专业与职业，尤其是高知识含量而又精细的创意类专业与职业，如动漫设计、游戏设计、考古学、文物鉴定等。',
                ARC: '适合的专业有园艺设计、动漫设计、绘画、舞蹈、雕塑、博物学学等。',
                ARE: '适合的专业有工业设计、绘画、舞蹈、会展设计、公告设计等。',
                ARI: '适合的专业有音乐、美术、设计艺术、包装工程、纺织工程、机械设计、服装设计、园艺学、建筑工程、博物学等。',
                ARS: '适合的专业有舞蹈、表演艺术、服装设计、美容美发设计、会展设计、戏剧、装潢设计等。',
                ASC: '适合的专业有艺术教育、戏剧、音乐、舞蹈、曲艺、文学、广告设计、装潢设计等。',
                ASR: '适合的专业是服装设计、艺术教育、表演艺术、装潢设计、会展设计、美容美发等等。',
                CAE: '如果测验结果可信，则表明您的性格极富有弹性，兼有事务型、创意型两类性格特征。但专门对应于这种性格的专业或职业很少，可以考虑事务性兼创意型两大类的专业与职业。如果测验过程中有疏漏或误解，则需进一步澄清。',
                CAI: '如果测验结果可信，则表明您的性格极富有弹性，兼有事务型、创意型两类性格特征。但专门对应于这种性格的专业或职业很少，可以考虑事务性兼创意型两大类的专业与职业。如果测验过程中有疏漏或误解，则需进一步澄清。',
                CAR: '如果测验结果可信，则表明您的性格极富有弹性，兼有事务型、创意型两类性格特征。但专门对应于这种性格的专业或职业很少，可以考虑事务性兼创意型两大类的专业与职业。如果测验过程中有疏漏或误解，则需进一步澄清。',
                CAS: '如果测验结果可信，则表明您的性格极富有弹性，兼有事务型、创意型两类性格特征。但专门对应于这种性格的专业或职业很少，可以考虑事务性兼创意型两大类的专业与职业。如果测验过程中有疏漏或误解，则需进一步澄清。',
                CEA: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑事务类和经营管理类的专业与职业，如印刷工程、行政管理、水利工程、勘查技术与工程、航海技术、市场营销、国际贸易、财务会计、金融学等。',
                CIA: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑事务类和研究类的专业与职业，如数学、金融工程、统计学、会计学、软件工程、测绘工程、信息工程等。',
                CRA: '这种性格很独特，专门对应于这种性格的专业或职业很少，但可以考虑事务类和实物类的专业与职业，如印刷工程、测绘工程、印刷工程、水利工程、自动化技术、财务管理、临床医学、基础医学、药学、档案学、博物学、图书馆学、包装工程等。',
                CSI: '适合的专业有档案学、图书馆学、汉语语言学、外国语、金融学、侦查学、行政管理、市场营销等。',
                EAC: '适合的专业有新闻学、出版学、动漫设计、传媒技术、工商管理、公告设计、表演艺术、市场营销等。',
                EAI: '适合的专业有新闻学、出版学、传媒技术、工商管理、公告设计、市场营销等。',
                ECA: '适合的专业有行政管理、工商管理、关公关系、财务管理、市场营销、国际贸易、金融理财、新闻学等。',
                ECR: '适合的专业有行政管理、工商管理、工程管理、财务管理、市场营销、物业管理、交通管理、法学等。',
                EIA: '适合的职业有城镇规划、工业工程、保险学、审计学、传媒技术、公共关系学等。',
                IAC: '适合的专业有中文、外国语、工业设计、纺织工程、园艺学、经济学、考古学、博物馆学等。',
                IAE: '适合的专业有中文、外国语、工业设计、纺织工程、园艺学、经济学、博物馆学、公共关系学、法学、心理学等。',
                ICA: '适合的专业有中文、外国语、考古学、人类学、数学、统计学、测绘学、工业制图、包装工程等。',
                ICE: '适合的专业有财政学、水利工程、中文、外国语、人类学、审计学、包装工程、工程管理、图书馆学、信息工程、侦查学等。',
                ICS: '适合的专业有历史学、财政学、安全工程、应用数学、教育学、文字学、语言学、行政学、药学、自动化工程、计算机科学、网络工程、包装工程、公共卫生学等。',
                IEA: '适合的专业有建筑工程、园艺工程、水利工程、经济学、冶金工程、材料工程、自动化工程、应用数学、社会学、软件工程、管理工程、化工工程、测绘工程、工业制图等。',
                IER: '适合的专业有心理学、气象学、水利工程、农业工程、地理学、经济学、网络工程、管理工程、环境工程、安全工程、财政学、市政规划、工业心理学、工业工程、计算机科学、应用数学等。',
                RAC: '适合的专业有园艺设计、机械设计、服装设计、建筑设计、工艺美术、舞蹈、雕塑、体育、纺织工程、钳工、磨具设计、工业制图、测绘工程等。',
                RAE: '适合的专业有园艺设计、雕塑、工艺美术、建筑设计、舞蹈、体育、服装设计、测绘工程、景观设计等。',
                RAS: '适合的专业有园艺设计、口腔医学、工艺美术、建筑设计、舞蹈、体育、服装设计、美容美发、景观设计等。',
                RCA: '适合的专业有工业制图、测绘工程、勘查工程与技术、雕刻、材料工程等。',
                REA: '适合的专业有仓储工程、物业管理、体育、舞蹈、交通管理、工程管理、机械工程、建筑工程、农业工程、景观设计等。',
                RSA: '这种性格的人比较少，但可以参考实物型和社会型两大类的专业与职业。比如相关的专业有广播电视编导、城市规划、装潢设计、舞蹈、体育、美容美发等。',
                RSC: '适合的专业有环境工程、仓储工程、航道工程、及排水工程、医疗器械工程、工程管理、信息管理等。',
                SAC: '专门对应于适合这种性格的专业与职业比较少，但可以参考社会型和艺术型两大类的专业与职业。如相关的专业有外国语、幼儿教育、艺术教育、语言教育、心理学、社会工作等。',
                SAI: '适合专业有牙科医学、学前教育、化学教育、哲学、宗教学、心理学、图书馆学、语言教育、护理学、特殊教育、社会工作等。',
                SAR: '专门对应于适合这种性格的专业与职业比较少，但可以参考社会型和艺术型两大类的专业与职业。如相关的专业有体育、幼儿教育、艺术教育、舞蹈、设计美术、社会工作、工业心理学等。',
                SCA: '适合的专业有对外汉语、外国语、治安学、社会工作、护理学、特殊教育、旅游管理、保险学、新闻学、心理学、工业制图等。',
                SCI: '适合的专业有对外汉语、外国语、治安学、社会工作、护理学、特殊教育、饭店管理、保险学、应用心理学等',
                SCR: '适合的专业有对兽医学、体育、治安学、化学教育、社会工作、护理学、特殊教育、饭店管理、保险学、应用心理学、烹饪等。',
                SRA: '适合的专业有广播电视编导、城市规划、体育、公共医学、牙医学、邮政、护理学、医疗器械工程、园艺、兽医学、饭店管理等。',
                SEI: '适合的典型专业有：公共事业管理、劳动与社会保障、法学、新闻学、资源环境与城乡规划管理、国际政治、历史学'
            },
            solution: '',
            //
            major_hld_first_choice_all: 0,
            major_hld_first_choice_history: 0,
            major_hld_first_choice_physics: 0,
            major_hld_second_choice: [],
        }
    },
    created() {
        this.major_analysis()
        // this.optional_major_analysis()
    },
    methods: {
        helpHandleClose() {
        },
        button_click(e) {
            this.show_help = true
            this.show_background = false
        },
        init_e_chart() {
            let chartDom = document.getElementById('e_chart_id');
            this.myChart = echarts.init(chartDom);
            let option;

            let that = this

            option = {
                title: {
                    text: '您的职业兴趣六边形',
                    left: 'center',
                    textAlign: 'left',
                    textStyle: {
                        color: '#ba0806'
                    },
                    padding: 10,
                },
                legend: {
                    data: ['', '']
                },
                radar: {
                    // shape: 'circle',
                    indicator: [
                        {name: 'E(企业型)', max: 20},
                        {name: 'S(社会型)', max: 20},
                        {name: 'I(研究型)', max: 20},
                        {name: 'A(艺术型)', max: 20},
                        {name: 'R(现实型)', max: 20},
                        {name: 'C(传统型)', max: 20}
                    ],
                },
                series: [
                    {
                        name: '',
                        type: 'radar',
                        data: [
                            {
                                value: that.hls_result,
                                name: '',
                                label: {
                                    show: true,
                                    formatter: function (params) {
                                        return params.value;
                                    },
                                },
                            }
                        ]
                    }
                ]
            };

            option && this.myChart.setOption(option);
        },
        confirm_question() {
            if (this.question_index > 0) {
                this.remind()
            }

            if ((this.question_index < 10 && this.question_index >= 0) || (this.question_index < 70 && this.question_index >= 60)) {
                this.outcome['r'] += 1
                console.log('r: ' + this.outcome['r'] + ' ' + this.question_index)
            } else if ((this.question_index < 20 && this.question_index >= 10) || (this.question_index < 80 && this.question_index >= 70)) {
                this.outcome['a'] += 1
                console.log('a: ' + this.outcome['a'] + ' ' + this.question_index)
            } else if ((this.question_index < 30 && this.question_index >= 20) || (this.question_index < 90 && this.question_index >= 80)) {
                this.outcome['i'] += 1
                console.log('i: ' + this.outcome['i'] + ' ' + this.question_index)
            } else if ((this.question_index < 40 && this.question_index >= 30) || (this.question_index < 100 && this.question_index >= 90)) {
                this.outcome['s'] += 1
                console.log('s: ' + this.outcome['s'] + ' ' + this.question_index)
            } else if ((this.question_index < 50 && this.question_index >= 40) || (this.question_index < 110 && this.question_index >= 100)) {
                this.outcome['e'] += 1
                console.log('e: ' + this.outcome['e'] + ' ' + this.question_index)
            } else if ((this.question_index < 60 && this.question_index >= 50) || (this.question_index < 120 && this.question_index >= 110)) {
                this.outcome['c'] += 1
                console.log('c: ' + this.outcome['c'] + ' ' + this.question_index)
            }

            if (this.question_index < 119) {
                this.question_index++
            } else {
                this.show_answer_status = !this.show_answer_status
                //this.show_answer()
            }

            console.log(this.outcome)
        },
        cancel_question() {
            console.log(this.outcome)
            console.log(this.question_index)
            if (this.question_index < 119) {
                this.question_index++
            } else {
                this.show_answer_status = !this.show_answer_status
                //this.show_answer()
            }
            if (this.question_index > 0) {
                this.remind()
            }
        },
        back_question() {
            if (this.question_index > 0) {
                this.question_index--
            }
        },
        show_answer() {
            let that = this
            this.question_info['r']['num'] = this.outcome['r']
            this.question_info['a']['num'] = this.outcome['a']
            this.question_info['i']['num'] = this.outcome['i']
            this.question_info['s']['num'] = this.outcome['s']
            this.question_info['e']['num'] = this.outcome['e']
            this.question_info['c']['num'] = this.outcome['c']

            let r = Object.keys(this.outcome).sort(function (a, b) {
                return that.outcome[a] - that.outcome[b]
            })
            console.log(r)
            this.sort_list = r

            this.solution = this.question_info[r[5]]['name'] + this.question_info[r[4]]['name'] + this.question_info[r[3]]['name']
            this.answer_info = this.answer[this.solution]
            this.send_answer()
        },
        send_answer() {
            let that = this
            axios.get(
                api.personality.hld, {params: {'key': this.solution, 'detail': this.outcome}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data)
                    that.begin_question_status = false
                    that.show_results()
                }
            }).catch(function (err) {
            });
        },
        get_major_direction(key = '') {
            let that = this
            let params = {}
            if (key !== '') {
                params.key = key
            }
            axios.get(
                api.personality.major_direction, {params: params}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    if (key !== '') {
                        that.hld_major_direction = res.data.data
                    } else {
                        that.major_direction = res.data.data
                    }
                }
            }).catch(function (err) {
            });
        },
        get_hld_major() {
            let that = this
            axios.get(
                api.personality.hld, {params: {'key': this.solution, 'detail': this.outcome}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log('--------------------------')
                    console.log(res.data)
                    that.hld_major_list = res.data.data
                    let major_l2_list = [];
                    for (let i in res.data.data) {
                        major_l2_list.push(res.data.data[i]['name'])
                    }
                    that.major_l2_list = major_l2_list.join('、')
                }
            }).catch(function (err) {
            });
        },
        get_hld_desc() {
            let that = this
            axios.get(
                api.personality.hld_desc, {params: {'key': this.solution}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data)
                    that.hld_major_desc = res.data.data
                }
            }).catch(function (err) {
            });
        },
        get_major_hld() {
            let that = this
            axios.get(
                api.personality.major_hld, {params: {'key': this.solution}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log('------------------')
                    console.log(res.data)
                    that.major_hld = res.data.data.list

                    that.optional_major_analysis_list = res.data.data.list
                    that.major_hld_first_choice_all = res.data.data.statistics.first_choice_all
                    that.major_hld_first_choice_history = res.data.data.statistics.first_choice_history
                    that.major_hld_first_choice_physics = res.data.data.statistics.first_choice_physics
                    that.major_hld_second_choice = res.data.data.statistics.second_choice
                    that.init_major_hld_e_chart()
                }
            }).catch(function (err) {
            });
        },
        init_major_hld_e_chart() {
            let that = this

            let first_chose = []
            if (that.major_hld_first_choice_all > 0) {
                first_chose.push({'value': that.major_hld_first_choice_all, 'name': '首选科目不限'})
            }
            if (that.major_hld_first_choice_history > 0) {
                first_chose.push({'value': that.major_hld_first_choice_history, 'name': '首选历史'})
            }
            if (that.major_hld_first_choice_physics > 0) {
                first_chose.push({'value': that.major_hld_first_choice_physics, 'name': '首选物理'})
            }

            let keys = []
            let values = []
            for (let i in this.major_hld_second_choice) {
                keys.push(i)
                values.push({"value": this.major_hld_second_choice[i], "name": i})
            }

            let chartDom = document.getElementById('major_hld_analysis');
            this.myChart = echarts.init(chartDom);
            let option;

            option = {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b}: {c} ({d}%)'
                },
                legend: {
                    data: keys
                },
                series: [
                    {
                        name: '首选科目',
                        type: 'pie',
                        selectedMode: 'single',
                        radius: [0, '30%'],
                        label: {
                            formatter: '{b}:{d}%',
                            position: 'inner',
                            fontSize: 14
                        },
                        labelLine: {
                            show: false
                        },
                        data: first_chose
                    },
                    {
                        name: '再选科目',
                        type: 'pie',
                        radius: ['45%', '60%'],
                        labelLine: {
                            length: 30
                        },
                        label: {
                            formatter: '{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ',
                            backgroundColor: '#F6F8FC',
                            borderColor: '#8C8D8E',
                            borderWidth: 1,
                            borderRadius: 4,
                            rich: {
                                a: {
                                    color: '#6E7079',
                                    lineHeight: 22,
                                    align: 'center'
                                },
                                hr: {
                                    borderColor: '#8C8D8E',
                                    width: '100%',
                                    borderWidth: 1,
                                    height: 0
                                },
                                b: {
                                    color: '#4C5058',
                                    fontSize: 14,
                                    fontWeight: 'bold',
                                    lineHeight: 33
                                },
                                per: {
                                    color: '#fff',
                                    backgroundColor: '#4C5058',
                                    padding: [3, 4],
                                    borderRadius: 4
                                }
                            }
                        },
                        data: values
                    }
                ]
            };

            option && this.myChart.setOption(option);
        },
        optional_major_analysis() {
            let that = this
            axios.get(
                api.user.optional_major_analysis, {params: {'key': this.solution}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log('------------------')
                    console.log(res.data)
                    that.optional_major_analysis_list = res.data.data
                }
            }).catch(function (err) {
            });
        },
        close_question() {
            this.begin_question_status = !this.begin_question_status
        },
        handleClose() {
            this.begin_question_status = !this.begin_question_status
        },
        show_major_detail(major_name, major_code) {
            let page = this.$router.resolve({path: '/majors', query: {major_code: major_code, major_name: major_name}})
            window.open(page.href, '_blank')
        },
        show_results() {
            let that = this
            axios.get(
                api.user.personality_test, {params: {}}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    console.log(res.data.data)
                    that.show_results_status = !that.show_results_status
                    if (res.data.data.hld.length > 1) {
                        that.solution = res.data.data.hld
                        let hld_detail = JSON.parse(res.data.data.hld_detail)
                        console.log(res.data.data.hld)
                        console.log(hld_detail)
                        that.outcome = hld_detail
                        that.question_info['r']['num'] = that.outcome['r']
                        that.question_info['a']['num'] = that.outcome['a']
                        that.question_info['i']['num'] = that.outcome['i']
                        that.question_info['s']['num'] = that.outcome['s']
                        that.question_info['e']['num'] = that.outcome['e']
                        that.question_info['c']['num'] = that.outcome['c']

                        let r = Object.keys(that.outcome).sort(function (a, b) {
                            return that.outcome[a] - that.outcome[b]
                        })
                        console.log(r)
                        that.sort_list = r

                        that.solution = that.question_info[r[5]]['name'] + that.question_info[r[4]]['name'] + that.question_info[r[3]]['name']
                        that.answer_info = that.answer[that.solution]

                        that.get_hld_major()
                        that.get_hld_desc()
                        that.get_major_hld()
                        that.get_major_direction(that.solution)

                        that.hls_result = [
                            that.outcome['e'],
                            that.outcome['s'],
                            that.outcome['i'],
                            that.outcome['a'],
                            that.outcome['r'],
                            that.outcome['c'],
                        ]

                        setTimeout(that.init_e_chart, 500);
                    } else {
                        ElMessageBox.alert('请先进行霍兰德性格测试 在查看结果', '提醒', {
                            confirmButtonClass: 'confirm_button_class',
                            confirmButtonText: '好的',
                            callback: () => {
                            },
                        })
                    }
                }
            }).catch(function (err) {
            });
        },
        begin_question() {
            let that = this
            this.begin_question_status = !this.begin_question_status
            setTimeout(function () {
                that.remind()
            }, 500)
        },
        remind() {
            if (this.question_index === 0) {
                ElMessageBox.alert('下面列出的活动，感兴趣的选“是”，反之则选“否”', '第一部分', {
                    type: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonClass: 'confirm_button_class',
                    callback: (action) => {
                    },
                })
            } else if (this.question_index === 60) {
                ElMessageBox.alert('下面列出的技能，能完成的选“是”，反之则选“否”', '第二部分', {
                    type: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonClass: 'confirm_button_class',
                    callback: (action) => {
                    },
                })
            }
        },
        majors_job_info_close() {
            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = []
            this.majors_job_list = []
        },
        show_majors_job(code, name) {
            let that = this

            this.show_majors_job_info = !this.show_majors_job_info
            this.majors_job_info = [{'name': name, 'code': code}]

            console.log(code)
            axios.post(
                api.open_major.majors_info, {'major_codes': [code], 'type': 1}
            ).then(function (res) {
                console.log(res)
                if (res.data.code === 10000) {
                    if (res.data.data.info !== undefined) {
                        that.majors_job_info = res.data.data.info
                        that.majors_job_list = res.data.data.list
                    } else {
                        that.majors_job_list = res.data.data
                    }
                    console.log(res.data.data)
                }
            }).catch(function (err) {
            });
        },
        handleClick() {
        },
        show_major_analysis(id) {
            this.chose_id = id
            this.tableData = [];
            for (let i in this.allTableData[id]) {
                this.tableData.push(this.allTableData[id][i])
            }
        },
        hld_collection(code, type) {
            let that = this
            axios.get(
                api.personality.save_code, {params: {'code': code, 'collection': type}}
            ).then(function (res) {
                if (res.data.code === 10000) {
                    if (parseInt(type) === 1) {
                        for (let i in that.hld_major_list) {
                            if (that.hld_major_list[i]['code'] == code || that.hld_major_list[i]['level2_code'] == code) {
                                that.hld_major_list[i]['collection'] = 0
                                break
                            }
                        }
                    } else {
                        for (let i in that.hld_major_list) {
                            if (that.hld_major_list[i]['code'] == code || that.hld_major_list[i]['level2_code'] == code) {
                                that.hld_major_list[i]['collection'] = 1
                                break
                            }
                        }
                    }
                    that.$notify({
                        title: '成功',
                        message: '操作成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        collection(code, type) {
            let that = this
            axios.get(
                api.personality.save_code, {params: {'code': code, 'collection': type}}
            ).then(function (res) {
                let chose_id = parseInt(that.chose_id)
                if (res.data.code === 10000) {
                    console.log(chose_id)
                    console.log(code)
                    console.log(parseInt(type) === 1)
                    console.log(that.allTableData)
                    console.log(that.tableData[code])
                    console.log(that.allTableData)
                    console.log(that.tableData)
                    if (parseInt(type) === 1) {
                        if (that.allTableData[chose_id][code] === undefined) {
                            for (let i in that.allTableData[chose_id]) {
                                if (that.allTableData[chose_id][i]['code'] == code || that.allTableData[chose_id][i]['level2_code'] == code) {
                                    that.allTableData[chose_id][i]['collection'] = 0
                                    break
                                }
                            }
                        } else {
                            that.allTableData[chose_id][code]['collection'] = 0
                        }
                        that.tableData = []
                        for (let i in that.allTableData[chose_id]) {
                            that.tableData.push(that.allTableData[chose_id][i])
                        }
                    } else {
                        if (that.allTableData[chose_id][code] === undefined) {
                            for (let i in that.allTableData[chose_id]) {
                                if (that.allTableData[chose_id][i]['code'] == code || that.allTableData[chose_id][i]['level2_code'] == code) {
                                    that.allTableData[chose_id][i]['collection'] = 1
                                    break
                                }
                            }
                        } else {
                            that.allTableData[chose_id][code]['collection'] = 1
                        }
                        that.tableData = []
                        for (let i in that.allTableData[chose_id]) {
                            that.tableData.push(that.allTableData[chose_id][i])
                        }
                    }
                    that.$notify({
                        title: '成功',
                        message: '操作成功',
                        type: 'success',
                        offset: 50,
                    });
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                console.log(err)
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
        major_analysis() {
            let that = this
            axios.post(
                api.user.major_analysis, {}
            ).then(function (res) {
                if (res.data.code === 10000) {
                    that.allTableData = res.data.data
                    console.log(that.allTableData)
                    for (let i in that.allTableData[30]) {
                        that.tableData.push(that.allTableData[30][i])
                    }
                } else {
                    that.$notify.error({
                        title: '错误',
                        message: res.data.data.msg,
                        offset: 50,
                    });
                }
            }).catch(function (err) {
                that.$notify.error({
                    title: '错误',
                    message: err.response.data.msg,
                    offset: 50,
                });
            });
        },
    }
}
</script>
<style scoped>
* {
    font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
    '微软雅黑', Arial, sans-serif;
}

.container {
    background-color: #FFFFFF;
    width: 80%;
    margin: 70px auto 0;
    padding: 0;
}

.title_info {
    position: relative;
    top: 35px;
    margin: 0 auto;
    width: 400px;
    height: 20%;
    background-color: #ba0806;
    line-height: 34px;
    border-radius: 5px;
    color: white;
    text-align: center;
    letter-spacing: 3px;
    z-index: 99;
}

.introduce {
    width: 1000px;
    padding: 30px;
    margin: 20px auto;
    border: 2px dashed tomato;
    border-radius: 5px;
    position: relative;
}

.cell {
    width: 500px;
    height: 80px;
    margin: 0 auto 10px;
    display: flex;
}

.tu {
    width: 80px;
    height: 80px;
    background-color: #ba0806;
    border-radius: 40px;
    margin-right: 10px;
    font-size: 20px;
    color: white;
    line-height: 80px;
    text-align: center;
    letter-spacing: 2px;
}

.c-title {
    width: 400px;
    height: 30px;
    font-size: 15px;
    line-height: 25px;
    text-align: left;
}

.c-jie {
    width: 400px;
    height: 55px;
    float: left;
    font-size: 11px;
    text-align: left;
    color: rgb(114, 113, 113);
}

.ming-2 {
    width: 500px;
    height: 20%;
    background-color: #ba0806;
    line-height: 30px;
    border-radius: 5px;
    color: white;
    text-align: center;
    letter-spacing: 5px;
    margin: 0 auto 10px;
}

.jia-nr {
    margin: 0 auto 10px;
    width: 500px;
    list-style-type: none;
    text-align: left;
    padding-bottom: 5px;
    line-height: 180%;
}

.jia-nr2 {
    margin: 0 auto 10px;
    width: 500px;
    list-style-type: none;
    text-align: left;
    padding-bottom: 5px;
    display: flex;
}

.jiantou {
    margin-top: 13px;
    width: 6%;
    height: 11%;
    margin-left: 3px;
    float: left;
    background-size: 100% 100%;
}

.one-tu {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 4px auto;
    background-color: #ba0806;
    background-size: 130% 130%;
    background-position: center;
}

.one-tu2 {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 4px auto;
    background-color: #ba0806;
    background-size: 130% 130%;
    background-position: center;
}

.one-tu3 {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 4px auto;
    background-color: #ba0806;
    background-size: 130% 130%;
    background-position: center;
}

.one-tu4 {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    margin: 4px auto;
    background-color: #ba0806;
    background-size: 130% 130%;
    background-position: center;
}

.one {
    width: 16%;
    height: 73%;
    margin-left: 10px;
    float: left;
}

.one-title {
    width: 100%;
    height: 20%;
    float: left;
    margin-top: 3px;
    font-size: 11px;
    line-height: 20px;
    text-align: center;
}

.one-word {
    font-size: 10px;
    text-align: center;
    color: rgb(114, 113, 113);
    white-space: pre-wrap;
}

.go {
    width: 200px;
    height: 40px;
    line-height: 40px;
    border-radius: 10px;
    background-color: #ba0806;
    margin: 0 auto;
    color: white;
    text-align: center;
    cursor: pointer;
}
</style>
<style>
.confirm_button_class {
    background-color: #ba0806 !important;
    border: 1px #ba0806 solid !important;
    color: white !important;
}

.demo-tabs .el-tabs__item {
    color: #666666;
    border: 0;
    text-align: center;
    padding: 0;
    width: 160px;
    font-size: 16px;
}

.demo-tabs .is-active {
    color: #ffffff;
    background-color: #ba0806;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-align: center;
    width: 160px;
    padding: 0;
    font-size: 16px;
}

.demo-tabs .el-tabs__active-bar {
    background-color: #ba0806;
    width: 160px;
}

.demo-tabs .el-tabs__nav-wrap::after {
    background-color: #ba0806;
}
</style>
